

<div *ngIf="configServise.token == null" style="width:100%;height:calc(100vh - 104px);background-image: url(assets/Slika.jpg);background-repeat: no-repeat;background-size: 100% ;">
    <div style="height:50px"></div>
    <mat-card style="width:250px;margin:auto">
        <form>
            <mat-form-field style="width:90%;display: table;margin:auto;">
                <mat-select [(value)]="data.country_db" placeholder="Country">
                  <mat-option *ngFor="let item of countries" [value]="item.country_code">{{ item.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width:90%;display: table;margin:auto;">
                <input matInput name="username" [(ngModel)]="data.username" placeholder="Username">
            </mat-form-field>
            <mat-form-field style="width:90%;display: table;margin:auto;">
                <input name="password" type="password" matInput [(ngModel)]="data.password" placeholder="Password">
            </mat-form-field>
            <p style="font-size: 10pt;color: darkred;white-space: pre-line;text-align: center;">{{ configServise.loginErrorMessage }}</p>
            <button mat-raised-button [style.background-color]="configServise.color" (click)="login()" style="margin:auto;color:white;display: table;margin:auto;">Login</button>
        </form>
    </mat-card>  
</div>
<h1 *ngIf="configServise.token != null" style="text-align: center;">{{ configServise.translationJSON?.success_login }}</h1>