import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {
  @ViewChild('drawer', { static: true }) sidenav: MatSidenav;
  opened = true;
  configService;
  my_gift = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => (<any>result).matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private changeDetectorRefs: ChangeDetectorRef, configService: ConfigService, private route: ActivatedRoute, private location: Location, private router: Router) {
    this.configService = configService;
    let self = this;
    setTimeout(function(){
      if(self.router.url && self.router.url.indexOf('my-gift') != -1) {
        self.my_gift = true;
      }
    }, 100);
  }

  ngOnInit() {
    this.configService.sideNavButton = this.sidenav;
    this.configService.path = location.pathname.split('/')[1];
    if(this.configService.path.indexOf('company') != -1) this.configService.path = 'companies';
    if(this.configService.path.indexOf('shop') != -1) this.configService.path = 'shops';
    if(this.configService.path.indexOf('users-requests') != -1 || this.configService.path.indexOf('user-requests') != -1) this.configService.path = 'users-requests';
    else if(this.configService.path.indexOf('user') != -1) this.configService.path = 'users';
    if(this.configService.path.indexOf('postnew') != -1) this.configService.path = 'postnews';
    if(this.configService.path.indexOf('postpromotion') != -1) this.configService.path = 'postpromotions';
    if(this.configService.path.indexOf('prizeartical') != -1) this.configService.path = 'prizearticals';
    if(this.configService.path.indexOf('bougthartical') != -1) this.configService.path = 'bougtharticals';
    if(this.configService.path.indexOf('product') != -1) this.configService.path = 'products';
    if(this.configService.path.indexOf('sale') != -1) this.configService.path = 'sales';
    if(this.configService.path.indexOf('faqsingle') != -1) this.configService.path = 'faq';
    if(this.configService.path.indexOf('category') != -1) this.configService.path = 'categories';
    if(this.configService.path.indexOf('terms_and_condition') != -1) this.configService.path = 'terms_and_conditions';
    if(this.configService.path.indexOf('qr-barcode-code') != -1) this.configService.path = 'qr-barcode-codes';
    if(this.configService.path.indexOf('message') != -1) this.configService.path = 'messages';
    if(this.configService.path.indexOf('gift') != -1) this.configService.path = 'gifts';
    if(this.configService.path.indexOf('promotion') != -1) this.configService.path = 'promotions';
    this.changeDetectorRefs.detectChanges();
  }

  setPath(path){
    this.configService.path = path;
  }
  
  logout(){
    this.configService.logout();
  }

  drawertoggle(){
    this.configService.sidenavToggle();
  }
}
