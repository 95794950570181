<div style="margin:10px">
    <h2 style="text-align:center">
        <span *ngIf="!update"> {{ configServise.translationJSON.add_category }} </span>
        <span *ngIf="update"> {{ configServise.translationJSON.edit_category }} </span>
        <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" routerLink="/categories"> {{ configServise.translationJSON.all_categories }} </button>
    </h2>
</div>
<mat-card>
    <mat-form-field style="width:50%;display:block">
        <input matInput [(ngModel)]="data.name" placeholder="Naziv">
    </mat-form-field>

    <p style="margin:0;font-weight:bold">{{ configServise.translationJSON.partner_logo }}:</p>
    <p style="margin:0;margin-top:15px"><b>{{ configServise.translationJSON.note }}:</b> {{ configServise.translationJSON.dimension_picture_1125x855pix }}</p>
    <app-file-uploader [(file_link)]="data.icon"></app-file-uploader>
    
    <div>
        <button *ngIf="!update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="addCategory()"> {{ configServise.translationJSON.add }} </button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="updateRecord()"> {{ configServise.translationJSON.save }} </button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.negativeColor"  routerLink="/categories" style="margin-left:30px;color:white"> {{ configServise.translationJSON.cancel }} </button>
    </div>
</mat-card>
