
<div style="margin:10px">
  <h2 style="text-align:center">
    {{ configServise.translationJSON.users }}
    <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="openPopup()"><mat-icon>subject</mat-icon>{{ configServise.translationJSON.report }}</button>
  </h2>
</div>
<div class="mat-elevation-z8" style="width:90%;margin:auto" >
    <div style="display:inline-block">
      <mat-form-field style="margin:20px;margin-bottom: 0;width:350px">
        <input matInput [(ngModel)]="currFilterValue" (keyup)="applyFilter()" placeholder="Filter">
      </mat-form-field>
    </div>
    
    <div style="float:right">
      <mat-form-field style="margin:20px;width:150px;vertical-align: top;width:250px;" >
        <mat-select [(value)]="orderData.column" placeholder="Sort po koloni" (selectionChange)="applyFilter()">
          <mat-option *ngFor="let item of columns" [value]="item.value">{{ item.view }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin:20px;width:150px;vertical-align: top;" >
        <mat-select [(value)]="orderData.order" (selectionChange)="applyFilter()">
          <mat-option [value]="'ASC'">ASC</mat-option>
          <mat-option [value]="'DESC'">DESC</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.user }}</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>
      
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.email }}</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>
      
      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.phone }}</th>
        <td mat-cell *matCellDef="let row">{{row.phone}}</td>
      </ng-container>
      
        
      <!-- Points Column -->
      <ng-container matColumnDef="money_available">
        <th mat-header-cell *matHeaderCellDef>Novcanik</th>
        <td mat-cell *matCellDef="let row">{{row.money_available ? row.money_available : 0}} RSD</td>
      </ng-container>

      <!-- Verificated Column -->
      <ng-container matColumnDef="is_admin">
        <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.admin_rights }}</th>
        <td mat-cell *matCellDef="let row">{{row.is_admin ? configServise.translationJSON.yes : configServise.translationJSON.no}}</td>
      </ng-container>
      
      <!-- Aktivan Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.active }}</th>
        <td mat-cell *matCellDef="let row">{{ row.active ? configServise.translationJSON.yes : configServise.translationJSON.no }}</td>
      </ng-container>
      
      
      <!-- answered_on_last_message Column -->
      <ng-container matColumnDef="answered_on_last_message">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"><mat-icon *ngIf="!row.is_answered_on_last_message" style="color: red;">mail</mat-icon></td>
      </ng-container>
      
      <!-- Created at Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.registration_date }}</th>
        <td mat-cell *matCellDef="let row">{{row.created_at | date:'yyyy-MM-dd'}}</td>
      </ng-container>
      
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:170px;"></th>
        <td mat-cell *matCellDef="let row" style="width:170px;">
            <!--<button *ngIf="row.active == 0" mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="unblock(row.phone)"><mat-icon>lock_open</mat-icon></button>
            <button *ngIf="row.active == 1" mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="block(row.phone)"><mat-icon>lock</mat-icon></button>-->
            <button mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="gotoEditUser(row.id)"><mat-icon>edit</mat-icon></button>
            <button *ngIf="row.admin == ''" mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white;margin-left:5px" (click)="deleteUser(row.id);$event.stopPropagation()"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover" (click)="gotoEditUser(row.id)" style="cursor:pointer"></tr>
    </table>

    <mat-paginator #paginator *ngIf="doneLoading"
        [length]="length"
        [(pageIndex)]="pageIndex"
        [(pageSize)]="pageSize"
        [pageSizeOptions]="[3, 15, 25, 50, 100, 250]"
        (page)="onPaginateChange($event)">
    </mat-paginator>
</div>
<div *ngIf="popup"  style="width:100%;height:100%;background-color:rgba(0, 0, 0, 0.7);z-index:9999;position:fixed;top:0;left:0;">
  <div style="position:fixed;min-height:400px;min-width:500px;background-color:white;top:17%;left:35%">
      <h1 style="font-style:italic;font-weight:bold;text-align:center">{{ configServise.translationJSON.report_per_user }}</h1>
      <!--<h2 style="text-align:center">Poslato: {{ cnt }} / {{ users.length }}</h2>
      <mat-progress-spinner
          style="margin-left:calc(50% - 50px)"
          class="example-margin"
          [color]="configServise.color"
          mode="determinate"
          [value]="(cnt / users.length) * 100">
      </mat-progress-spinner>
      <h2 style="text-align:center">{{ (cnt / users.length) * 100 | number:'1.0-0' }} %</h2>
      <p style="text-align:center;margin-top:10px">Molimo Vas sačekajte,<br/>i nemojte zatvarati ovaj prozor.</p>-->
      <div style="margin:30px 60px;padding: 40px;padding-top:15px;margin-bottom:0">          
        <div style="display:table;margin:auto;margin-bottom:20px;">     
          <mat-radio-group [(ngModel)]="dataIzvestaj.tip" (change)="alert = false" name="radio" style="display:block;margin-bottom:15px;margin-top:15px">
              <mat-radio-button name="radio" [value]="0" style="margin-right:50px">{{ configServise.translationJSON.by_date }}</mat-radio-button>
              <mat-radio-button name="radio" [value]="1">{{ configServise.translationJSON.up_to_now }}</mat-radio-button>
          </mat-radio-group> 
        </div>
        <div style="display:table;margin:auto;;min-height:66px">     
          <p *ngIf="!dataIzvestaj.tip" style="display:inline-block;font-weight:bold;margin-right:30px">{{ configServise.translationJSON.from }}: </p>
          <mat-form-field *ngIf="!dataIzvestaj.tip">
              <input matInput [matDatepicker]="picker" [(ngModel)]="dataIzvestaj.od" placeholder="MM/DD/YYYY" [ngModelOptions]="{timezone: 'UTC'}">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>          
        </div>
        <div style="display:table;margin:auto;min-height:66px">
          <p *ngIf="!dataIzvestaj.tip" style="display:inline-block;font-weight:bold;margin-right:30px;">{{ configServise.translationJSON.to }}: </p>
          <mat-form-field *ngIf="!dataIzvestaj.tip">
              <input matInput [matDatepicker]="picker2" [(ngModel)]="dataIzvestaj.do" placeholder="MM/DD/YYYY">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div style="height: 30px;">
        <p *ngIf="alert" style="font-weight:bold;margin:0px;text-align: center;" [style.color]="configServise.color">{{ alertText }}</p>
      </div>
      <div style="margin-bottom:30px">
        <div style="display:inline-block;width:50%">
          <div style="display:table;margin:auto;">
            <img *ngIf="waiting" src="assets/loading-png-gif-original.gif" style="width:30px" />
            <button *ngIf="!waiting" mat-raised-button [style.background-color]="configServise.color" style="color:white" (click)="generisiExcel()"><mat-icon>check</mat-icon>{{ configServise.translationJSON.generate }}</button>
          </div>
        </div>
        <div style="display:inline-block;width:50%">
          <div style="display:table;margin:auto;">
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color:white" (click)="closePopup()"><mat-icon>clear</mat-icon>{{ configServise.translationJSON.back }}</button>
          </div>
        </div>
      </div>
  </div>
</div>