import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { ImportDialog } from './import-dialog/import-dialog.component';

@Component({
  selector: 'app-qr-barcode-codes',
  templateUrl: './qr-barcode-codes.component.html',
  styleUrls: ['./qr-barcode-codes.component.css']
})



export class QRBarcodeCodesComponent implements AfterViewInit,OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  allData:any = [];
  dataSource: any = [];
  configServise;
  wait = 0;
  length = 0;
  @Input() vouchers_id: any = 'all';
  vouchers_amounts_value: any = 'all';
  pageIndex = "0";
  pageSize = "15"; 
  vouchers_amounts: any = [];
  vouchers: any = [];
  doneLoading = true;
  @Input() showVendorMatSelect: any = true;
  
 
  constructor(private https: HttpClient, configServise: ConfigService, public dialog:MatDialog, public route: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router) {    
    this.configServise = configServise;
  }

  displayedColumns = ['id', 'type', 'code', 'voucher_name', 'vouchers_amounts_value', 'is_used', 'action'];

  ngOnInit() {
    if(this.vouchers_id != 'all' && typeof this.vouchers_id === 'string') {
      this.vouchers_id = parseInt(this.vouchers_id); 
    }
    let pageIndex = this.route.snapshot.queryParamMap.get("pageIndex");
    if(pageIndex) this.pageIndex = pageIndex;
    let pageSize = this.route.snapshot.queryParamMap.get("pageSize");
    if(pageSize) this.pageSize = pageSize;  
    let vouchers_id = this.route.snapshot.queryParamMap.get("vouchers_id");
    if(vouchers_id) {
      this.vouchers_id = parseInt(vouchers_id);  
    }
    if(this.vouchers_id != 'all'){
      this.getVouchersAmountsByVoucherID(this.vouchers_id);
      let vouchers_amounts_value = this.route.snapshot.queryParamMap.get("vouchers_amounts_value");
      if(vouchers_amounts_value) this.vouchers_amounts_value = parseInt(vouchers_amounts_value);  
    }     
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.doneLoading = false;
    this.dataSource = new MatTableDataSource();
    let body: any = {};
    if(this.vouchers_id != 'all') body.vouchers_id = this.vouchers_id;
    if(this.vouchers_amounts_value != 'all') body.vouchers_amounts_value = this.vouchers_amounts_value;
    if(this.vouchers_id != 'all') body.vouchers_id = this.vouchers_id;
    this.configServise.httpPost(this.configServise.getAPI() + 'qr_barcodes/' + this.pageIndex + '/' + this.pageSize, body).subscribe(data => {
      this.length = (<any>data).count;
      this.doneLoading = true;
      this.allData = <Array<any>> (<any>data).data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.allData;
      this.table.dataSource = this.dataSource;
    });
    this.configServise.httpGet(this.configServise.getAPI() + 'vouchers').subscribe(data => {
      this.vouchers = data;
    });
  }

  deleteQR_Barcode(id){
      this.configServise.httpDelete(this.configServise.getAPI() + 'delete_qr_barcode/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.dataSource.data = <Array<any>> data;
          this.changeDetectorRefs.detectChanges();
        }
        else {
          alert(tmp.text);
        }
      });
    //}
  }
  gotoEditQR_Barcode(id){
    this.router.navigate(['qr-barcode-code'], { queryParams: { id: id }});
  }
  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: "Sigurni ste da zelite da obrisece QR/Barcode" + "?"
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if(result && result.agree){
        this.deleteQR_Barcode(id);
      }
    });
  }

  getVouchersAmountsByVoucherID(voucher_id){
    this.vouchers_amounts = [];
    this.vouchers_amounts_value = 'all';
    this.configServise.httpGet(this.configServise.getAPI() + 'vouchers/' + voucher_id + '/all_vouchers_amounts').subscribe(data => {
      this.vouchers_amounts = data;
    });
  }

  applyFilter(filterValue: string = null) {
    this.wait++ 
    let self = this;
    setTimeout(() => { 
      self.wait-- 
      if(self.wait == 0) {  
        self.pageIndex = "0";   
        self.router.navigate([], {
          relativeTo: self.route,
          queryParams: {
            pageSize: self.pageSize,
            pageIndex: self.pageIndex,
            vouchers_id: this.vouchers_id,
            vouchers_amounts_value: this.vouchers_amounts_value
          },
          queryParamsHandling: 'merge',
          // preserve the existing query params in the route
          skipLocationChange: false
          // do not trigger navigation
        });    
        self.ngAfterViewInit();
      }
    }, 1000);
  }

  onPaginateChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageSize: e.pageSize,
        pageIndex: e.pageIndex,
        vouchers_id: this.vouchers_id,
        vouchers_amounts_value: this.vouchers_amounts_value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    //this.ngOnInit();
    this.ngAfterViewInit();
  }

  ResetFilter (){
    this.vouchers_id = 'all';
    this.vouchers_amounts_value = 'all';
    
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        vouchers_id: this.vouchers_id,
        vouchers_amounts_value: this.vouchers_amounts_value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });

    this.ngAfterViewInit();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ImportDialog, {
      width: '600px',
      data: { vouchers: this.vouchers },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngAfterViewInit();
    });
  }


}
