<div style="margin:10px">
    <h2 style="text-align:center">
        <span>{{ configServise.translationJSON.details_for_ordered_gift }}</span>
        <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="goback()">{{ configServise.translationJSON.all_ordered_gifts }}</button>
    </h2>
</div>
<mat-card>
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.user }}: </p>
        <p>{{ data.user_name }}</p>
    </div>
    <div style="height:20px"></div>
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.phone }}: </p>
        <p>{{ data.phone }}</p>
    </div>
    <div style="height:20px"></div>
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.gift }}: </p>
        <p>{{ data.artical_name }}</p>
    </div>
    <div style="height:20px"></div>
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.quantity }}: </p>
        <p>{{ data.quantity }}</p>
    </div>
    <div style="height:20px"></div>
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.date }}: </p>
        <p>{{ data.created_at }}</p>
    </div>
    <div style="height:20px"></div>
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.points }}: </p>
        <p>{{ data.points }}</p>
    </div>
    <div style="height:20px"></div>
    <div>
        <p style="font-weight:bold;margin-right:20px;width:200px">{{ configServise.translationJSON.active_user }}: </p>
        <p *ngIf="data.user_active" [style.background-color]="data.user_active ? 'darkgreen' : 'red'" style="color:white;padding:7px;border-radius:5px">{{data.user_active ? configServise.translationJSON.yes : configServise.translationJSON.no }}</p>
    </div>
    <div style="height:20px"></div>
    <img style="margin-top:0px;width:200px;display:block" [src]="data.icon" />
    <mat-checkbox [(ngModel)]="data.isporucen" color="primary">{{ configServise.translationJSON.deliver }}</mat-checkbox>
    <div *ngIf="data.isporucen">
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.send_date }}: </p>
        <mat-form-field>
            <input matInput [matDatepicker]="picker" [(ngModel)]="data.sent_date" placeholder="MM/DD/YYYY">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>        
        <div>
            <mat-form-field style="width:35%" *ngIf="data.vouchers_id == null">
                <input type="text" [placeholder]="configServise.translationJSON.voucher" matInput [matAutocomplete]="auto" [(ngModel)]="vouchername" [formControl]="myControl">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectVoucher($event)">
                    <mat-option  *ngFor="let option of filteredOptions | async" [value]="option.id">
                        {{option.name_formatted}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="data.vouchers_id != null">
                <span style="font-size:9pt;display:block;margin-bottom:10px">{{ configServise.translationJSON.voucher }}</span>
                <div>
                    <p style="margin-top:5px;margin-right:40px;display:inline-block;vertical-align: top;">{{ vouchername }}</p>
                    <button mat-mini-fab [style.background-color]="configServise.negativeColor" style="color: white;width:30px;height:30px;" (click)="clearVoucherId()"><mat-icon style="margin-top:-9px">clear</mat-icon></button>
                </div>
            </div>
        </div>    
        <div>            
            <mat-form-field style="width:40%;display:inline-block">
                <input matInput [(ngModel)]="data.serial_number" maxlength="120" [placeholder]="configServise.translationJSON.serial_number">
            </mat-form-field>            
            <button *ngIf="locked" mat-raised-button [style.background-color]="configServise.color" style="color:white;margin-left:10px" (click)="editSeralNumber()">{{ configServise.translationJSON.edit_voucher_serial_number }}</button>
        </div>
        <div style="height:10px"></div>    
        <mat-card style="margin-bottom:50px;display:inline-block;margin-right:20px;width:380px;height:100%">
            <p style="margin:0;font-weight:bold">{{ configServise.translationJSON.voucher_image }}:</p>
            <p style="margin:0;margin-top:15px"><b>{{ configServise.translationJSON.note }}:</b> {{ configServise.translationJSON.dimension_picture_1125x855pix }}</p>
            <app-file-uploader [(file_link)]="data.icon_voucher"></app-file-uploader>
        </mat-card>
        <div style="margin-top:30px">
            <button *ngIf="!locked" mat-raised-button [style.background-color]="configServise.color" style="color:white" (click)="save()">{{ configServise.translationJSON.send_gift }}</button>
            <div>
                <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.voucher_used }}: </p>
                <p>{{ data.voucher_spent ? configServise.translationJSON.yes : configServise.translationJSON.no }}</p>
            </div>
        </div>
    </div>
</mat-card>
