import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-new-vouchers',
  templateUrl: './add-new-voucher.component.html',
  styleUrls: ['./add-new-voucher.component.css']
})
export class AddNewVoucherComponent implements OnInit {
  data: any = { promotion: 0, promotion_percent: 0, cash_back_percent: 0 };
  update:any = null;
  validForm = true;
  configServise;
  promotion:any  = [];
  categories: any = [];
  vouchers_amounts: any = [];
  checked_all_categories = false;
  constructor(private https: HttpClient, configServise: ConfigService, private router: Router,public dialog: MatDialog, private route: ActivatedRoute, public datepipe: DatePipe) {
    this.configServise =configServise;1
  }

  ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    this.configServise.httpGet(this.configServise.getAPI() + 'categories').subscribe(datacategories => {
      this.categories = datacategories;
      if(this.update){  
          this.configServise.httpGet(this.configServise.getAPI() + 'vouchers/' + this.update).subscribe(data => {
            this.data = data;
            this.data.photo = this.data.icon;
            this.data.vouchers_x_categories.forEach(element => {
              this.categories.forEach(cat => {
                if(cat.id == element.categories_id) {
                  cat.checked = true;
                }
              });
            });
            this.checkForAll();
        });
      }
    });
  }

  insert_or_update_voucher(){
    if(this.validateForm()){
      this.data.vouchers_x_categories = [];
      this.categories.forEach(cat => {
        if(cat.checked){
          this.data.vouchers_x_categories.push({ categories_id: cat.id });
        }
      });
      this.configServise.httpPost(this.configServise.getAPI() + 'insert_or_update_voucher', this.data).subscribe(data => {
        let tmp: any = data;
        if(tmp.result == true){
          this.router.navigate(['vouchers']);
        }
      });
    } 
  }

  validateForm(){
    if(this.data.name == "" || this.data.name == null || this.data.name == undefined) {
      this.openAlert(this.configServise.translationJSON.enter_a_name + "!");
      return false
    }
    else if(this.data.description == "" || this.data.description == null || this.data.description == undefined) {
      this.openAlert(this.configServise.translationJSON.enter_a_description + "!");
      return false
    }
    else if(!this.data.icon) {
      this.openAlert(this.configServise.translationJSON.put_a_picture); 
      return false;
    }
    else if(!this.data.icon_list) {
      this.openAlert(this.configServise.translationJSON.put_a_picture); 
      return false;
    }
    else if(this.data.promotion && !this.data.promotion_percent){
      this.openAlert("Popunite procenat."); 
      return false;
    }
    else if(!this.data.promotion && !this.data.cash_back_percent){
      this.openAlert("Popunite procenat."); 
      return false;
    }
    return true;
  } 

  openAlert(msg): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result && result.agree) {

      }
    });
  }

  checkLength(event){
    if(event.keyCode == 69 || event.srcElement.value.length > 1 && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))){
      event.preventDefault();
    };
  }
  validate(e){
    if(e.key != 0
      && e.key != 1
      && e.key != 2
      && e.key != 3
      && e.key != 4
      && e.key != 5
      && e.key != 6
      && e.key != 7
      && e.key != 8
      && e.key != 9
      && e.key != 10
      && e.key != "Backspace"
      && e.key != "Delete"
      && e.key != "ArrowLeft"
      && e.key != "ArrowRight"
      && e.key != "ArrowUp"
      && e.key != "ArrowDown"
      && e.key != ","
      && e.key != ".") {
        e.preventDefault();
      }
  }

  onPromotionChange(e: any) {
    this.data.promotion_percent = 0;
    this.data.cash_back_percent = 0;
  }

  click_all(){
    this.categories.forEach(data => {
      if(this.checked_all_categories) {
        data.checked = false;
      }
      else data.checked = true;
    });
  }
  checkForAll(item = null){
    let svi = true;
    this.categories.forEach(data => {
      if(item != null && (!data.checked && item.id != data.id || data.checked && item.id == data.id)) {
        svi = false;
      }
      if(item == null && !data.checked) {
        svi = false;
      }
    });
    this.checked_all_categories = svi;
  }
  
}
