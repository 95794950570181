

<div style="height:20px;padding:10px;">
  <mat-progress-bar *ngIf="false" mode="indeterminate"></mat-progress-bar>
</div>
<h2 style="text-align: center;margin-top:0">Import gift kodova</h2>
<div style="padding:50px;width: 450px;display: table;margin:auto;">
    <div style="display: table;margin:auto">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="dataImport.type" style="display: block;margin-bottom:25px;">
          <mat-radio-button [value]="'QRcode'" style="margin-right:70px">QR</mat-radio-button>
          <mat-radio-button [value]="'Barcode'" >Barcode</mat-radio-button>
      </mat-radio-group>
      <mat-form-field style="width:90%;display: table;margin:auto">
        <mat-label *ngIf="!dataImport.vouchers_id">Vendor</mat-label>
        <mat-select #sl1 [(value)]="dataImport.vouchers_id" color="accent" (selectionChange)="getVouchersAmountsByVoucherID(dataImport.vouchers_id)">
          <button (click)="sl1.close()" style="width:22px;background-color: #F12C11;color:white;border-radius: 5px;border:none;position: absolute;right: 0;z-index: 10001;">x</button>
          <mat-option *ngFor="let voucher of vouchers" [value]="voucher.id">{{ voucher.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width:90%;display: table;margin:auto">
        <mat-label *ngIf="!dataImport.vouchers_amounts_id">Vrednost</mat-label>
        <mat-select #sl2 [(value)]="dataImport.vouchers_amounts_id" color="accent">
          <button (click)="sl2.close()" style="width:22px;background-color: #F12C11;color:white;border-radius: 5px;border:none;position: absolute;right: 0;z-index: 10001;">x</button>
          <mat-option *ngFor="let vouchers_amount of vouchers_amounts" [value]="vouchers_amount.id">{{ vouchers_amount.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="height: 20px;"></div>
    <div style="min-height: 80px;">
      <div style="padding:10px;border: 1px solid lightgray;border-radius: 10px">
        <b>Napomena:</b> Excel mora ispunjavati sledeća pravila:<br/>
        <div style="margin-left:25px">
          - popunjena samo prva kolona (kolona A)<br/>
          - pocinje od prvog reda (nema headere)<br/>
          - ne sme sadrzati prazne redove izmedju kodova
        </div>
      </div>
      <p style="text-align: center;white-space: pre-line;">{{ message }}</p>
    </div>
    <div style="height: 10px;"></div>

    <button *ngIf="message == '' && !loading && dataImport.vouchers_id && dataImport.vouchers_amounts_id" mat-raised-button style="width: 120px;vertical-align: top;height: 35px;color:white;display:table;
                                    margin:auto"
                            [style.background-color]="configService.color"
                            (click)="file.click()"
            >
      <mat-icon style="position: absolute;font-size:15px;
                      left: 10px;
                      top: 10px;">download</mat-icon> Import
    </button>
    <button *ngIf="message == '' && (loading || !dataImport.vouchers_id || !dataImport.vouchers_amounts_id)" mat-raised-button style="width: 120px;vertical-align: top;height: 35px;color:white;display:table;
                                    background-image: linear-gradient(to right, lightgray , lightgray);margin:auto"
            >
      <mat-icon style="position: absolute;font-size:15px;
                      left: 10px;
                      top: 10px;">download</mat-icon> Import
    </button>
    <button *ngIf="message != ''" mat-raised-button style="width: 120px;vertical-align: top;height: 35px;color:white;display:table;
                                    margin:auto"
            [style.background-color]="configService.color"
            (click)="onNoClick()"
            >
      <mat-icon style="position: absolute;font-size:15px;
                      left: 10px;
                      top: 10px;">check</mat-icon> U redu
    </button>
    <div>        
        <input #file type="file" class="form-control" style="display:none" [disabled]="loading" 
                (change)="addfile($event)"
                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">        
    </div> 
</div>
<div style="height:20px;padding:10px;">
  <mat-progress-bar *ngIf="false" mode="indeterminate"></mat-progress-bar>
</div>