import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements AfterViewInit, OnInit {
  configServise;
  data: any = {};
  edit_mode = false;

  constructor(private https: HttpClient, configServise: ConfigService, public dialog:MatDialog,
              private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
                this.configServise =configServise;
  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.configServise.httpGet(this.configServise.getAPI() + 'terms_and_conditions').subscribe(data => {
      this.data = data;
      if(!data) {
        this.edit_mode = true;
        this.data = {};
      }
    });
  }

  saveTerms(){
    this.configServise.httpPost(this.configServise.getAPI() + 'set_terms_and_conditions', this.data).subscribe(data => {
      this.data = data;
      this.edit_mode = false;
    });
  }


}
