import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
})
export class AdministrationComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  allData:any = [];
  searchColumn = 'all';
  currFilterValue = "";
  configServise;  
  alert = false;
  alertText = "* Polja su obavezna";
  popup = false;
  dataIzvestaj: any = { tip: 0 };
  waiting = false;
  wait = 0;
  options: any = [];
  myControl = new FormControl();
  filteredOptions: any;
  user: any = {
    id: null,
    name: "",
    email: "",
    admin: {}
  };

  constructor(private https: HttpClient, configServise: ConfigService,
              public datepipe: DatePipe,
              private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
                this.configServise =configServise;
  }

  ngOnInit() {  
    this.filter('');
  }
  
  public filter(filterValue: string = null) {
    this.wait++;
    this.currFilterValue = filterValue;
      let self = this;
      setTimeout(() => {
        self.wait--
        if(self.wait == 0) {   
          //if(this.currFilterValue != ''){          
            self.configServise.httpPost(this.configServise.getAPI() + 'users/0/5000' + (this.currFilterValue != '' ? '/' + this.currFilterValue : ''), {}).subscribe(data => {
              let users = (<any>data).data;
              users.forEach((element: any) => {
                if(element.admin && element.admin != "") {
                  element.admin = JSON.parse(element.admin);
                }
              });
              this.filteredOptions = users;
              this.checkForAll();
            });
          //}
        }
      }, 1000);
    this.filteredOptions = [];
  }

  public selectUser(event){
    this.user.id = event.option.value;
    this.filteredOptions.forEach(element => {
      if(element.id == event.option.value){
        this.user = element;
        if(element.admin) {
          this.user.admin = JSON.parse(element.admin);
        }
        else this.user.admin = {};
      }
    });
  }

  public clearUserId(){
    this.user = {
      id: null,
      name: "",
      email: "",
      admin: {}
    };
  }

  ngAfterViewInit() {

  }   

  save(){
    let body = this.user;
    body.admin = JSON.stringify(body.admin);
    this.configServise.httpPost(this.configServise.getAPI() + 'setadmin', body).subscribe(data => {
      if((<any>data).result) {
        this.clearUserId();
        this.ngOnInit();
      }
      else {
        alert(this.configServise.translationJSON.unsuccessful + ".");
      }
    });
  }
  click_vouchers(){
    if(this.user.admin.vouchers) {
      this.user.admin.voucher = false;
    }
    else this.user.admin.voucher = true;
    this.checkForAll();
  }
  click_categories(){
    if(this.user.admin.categories) {
      this.user.admin.category = false;
    }
    else this.user.admin.category = true;
    this.checkForAll();
  }
  click_qr_barcode(){
    if(this.user.admin["qr-barcode-codes"]) {
      this.user.admin["qr-barcode-code"] = false;
    }
    else this.user.admin["qr-barcode-code"] = true;
    this.checkForAll();
  }
  click_message(){
    if(this.user.admin["messages"]) {
      this.user.admin["message"] = false;
    }
    else this.user.admin["message"] = true;
    this.checkForAll();
  }
  click_terms_conditions(){
    if(this.user.admin.terms_and_conditions) {
      this.user.admin.terms_and_condition = false;
    }
    else this.user.admin.terms_and_condition = true;
    this.checkForAll();
  }
  click_gifts(){
    if(this.user.admin["gifts"]) {
      this.user.admin["gift"] = false;
    }
    else this.user.admin["gift"] = true;
    this.checkForAll();
  }
  click_users(){
    if(this.user.admin["users"]) {
      this.user.admin["user"] = false;
    }
    else this.user.admin["user"] = true;
    this.checkForAll();
  }
  click_promotions(){
    if(this.user.admin["promotions"]) {
      this.user.admin["promotion"] = false;
    }
    else this.user.admin["promotion"] = true;
    this.checkForAll();
  }
  //mora se rucno za svaki postaviti
  setAllProps(value){
    this.user.admin.vouchers = value;
    this.user.admin.voucher = value;
    this.user.admin.categories = value;
    this.user.admin.category = value;
    this.user.admin["qr-barcode-codes"] = value;
    this.user.admin["qr-barcode-code"] = value;
    this.user.admin.messages = value;
    this.user.admin.message = value;
    this.user.admin.terms_and_conditions = value;
    this.user.admin.terms_and_condition = value;
    this.user.admin.gifts = value;
    this.user.admin.gift = value;
    this.user.admin.users = value;
    this.user.admin.user = value;
    this.user.admin.promotions = value;
    this.user.admin.promotion = value;
    this.user.admin.dashboard = value;
    this.user.admin.administration = value;
  }
  
  checkForAll(){ 
    let svi = true; 

    if(!this.user.admin.vouchers) svi = false; 
    if(!this.user.admin.voucher) svi = false;
    if(!this.user.admin.categories) svi = false;
    if(!this.user.admin.category) svi = false;
    if(!this.user.admin["qr-barcode-codes"]) svi = false;
    if(!this.user.admin["qr-barcode-code"]) svi = false;
    if(!this.user.admin.messages) svi = false;
    if(!this.user.admin.message) svi = false;
    if(!this.user.admin.terms_and_conditions) svi = false;
    if(!this.user.admin.terms_and_condition) svi = false;
    if(!this.user.admin.gifts) svi = false;
    if(!this.user.admin.gift) svi = false;
    if(!this.user.admin.users) svi = false;
    if(!this.user.admin.user) svi = false;
    if(!this.user.admin.promotions) svi = false;
    if(!this.user.admin.promotion) svi = false;
    if(!this.user.admin.dashboard) svi = false;
    if(!this.user.admin.administration) svi = false;

    this.user.admin.all = svi;
  }
  click_all(){
    this.setAllProps(!this.user.admin.all);
  }
  formaZaDodavanje(){
    this.clearUserId();
    this.user.id = 0;
  }

}
