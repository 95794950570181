import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  configServise;
  users: any;
  index = 0;
  data: any = {};
  popup = false;
  cnt = 0;
  constructor(configServise: ConfigService, private dialog:MatDialog,private https: HttpClient) {
    this.configServise = configServise;
   }

  ngOnInit() {
    this.configServise.httpGet(this.configServise.getAPI() + 'users').subscribe(data => {
      this.users = data;
    });
  }

  sendSMS(){
   // if(confirm("Da li ste sigurni da želite da pošaljete notifikaciju SVIM korisnicima?")){
      this.popup = true;
      this.configServise.sidenavToggle();
      this.index = 0;
      this.send(this.users[this.index].id);
    //}
  }

  openDialog1(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: this.configServise.translationJSON.are_you_sure_you_want_to_send_the_notification_to_all_users + "?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if(result && result.agree){
        this.sendSMS();
      }
    });
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(undefined), ms)).then(()=>console.log("fired"));
  }

  send(id){
    this.configServise.httpPost(this.configServise.getAPI() + 'sendsmsto/' + id, this.data).subscribe(data => {
      let tmp: any;
      tmp = data;
      if(tmp.result == true) {
        if(this.cnt == 0) {
          this.configServise.httpPost(this.configServise.getAPI() + 'logsmstoall', this.data).subscribe(data => {

          });
        }
        this.cnt++;
        if(this.users.length == this.cnt){
          this.popup = false;
          this.configServise.sidenavToggle();
          this.cnt = 0;
        }
        if(this.index < this.users.length){
          this.index++;
          this.delay(6000).then(any=>{
            this.send(this.users[this.index].id);
          });
        }
      }
      else console.log(data);
    });
  }

}
