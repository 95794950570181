<div style="margin:10px">
    <h2 style="text-align:center">
        <span *ngIf="!update">Dodaj vendor</span>
        <span *ngIf="update">Izmeni vendor</span>
        <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" routerLink="/vouchers">Svi vendori</button>
    </h2>
</div>
<mat-card>
    <mat-form-field style="width:50%;display:block">
        <input matInput [(ngModel)]="data.name" [placeholder]="configServise.translationJSON.name">
    </mat-form-field>
    <mat-form-field style="width:50%;display:block">
        <textarea matInput [(ngModel)]="data.description" [placeholder]="configServise.translationJSON.description" style="height: 130px;"></textarea>
    </mat-form-field>

    <p>Promocija</p>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="data.promotion" style="display: block;padding-left:0px;margin-bottom:25px;">
        <mat-radio-button [value]="1" style="margin-right:70px" (change)="onPromotionChange($event)">DA</mat-radio-button>
        <mat-radio-button [value]="0" (change)="onPromotionChange($event)">NE</mat-radio-button>
    </mat-radio-group>

    <mat-form-field style="width:50%;display:block" *ngIf="data.promotion == 1" >
        <input matInput [(ngModel)]="data.promotion_percent" placeholder="Procenat promocije">
    </mat-form-field>

    <mat-form-field style="width:50%;display:block" *ngIf="data.promotion == 0" >
        <input matInput [(ngModel)]="data.cash_back_percent" placeholder="Procenat povrata novca">
    </mat-form-field>

    <div>
        <p>Kategorije</p>
        <mat-checkbox [(ngModel)]="checked_all_categories" (click)="click_all()" style="display: block;">{{ configServise.translationJSON.all }}</mat-checkbox>
        <div style="padding-left: 20px;">
            <mat-checkbox *ngFor="let item of categories" [(ngModel)]="item.checked" style="display: block;margin-left:20px" (click)="checkForAll(item)">{{ item.name }}</mat-checkbox>
        </div>
    </div>

    <div style="height: 50px;"></div>
    
    <p style="margin:0;font-weight:bold">{{ configServise.translationJSON.partner_logo }}:</p>
    <p style="margin:0;margin-top:15px"><b>{{ configServise.translationJSON.note }}:</b> {{ configServise.translationJSON.dimension_picture_1125x855pix }}</p>
    <app-file-uploader [(file_link)]="data.icon"></app-file-uploader>
    
    
    <p style="margin:0;font-weight:bold">{{ configServise.translationJSON.partner_logo }}:</p>
    <p style="margin:0;margin-top:15px"><b>{{ configServise.translationJSON.note }}:</b> {{ configServise.translationJSON.dimension_picture_1125x855pix }}</p>
    <app-file-uploader [(file_link)]="data.icon_list"></app-file-uploader>

    <div style="margin-top: 50px;margin-bottom:50px">
        <button *ngIf="!update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="insert_or_update_voucher()">{{ configServise.translationJSON.add }}</button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="insert_or_update_voucher()">{{ configServise.translationJSON.save }}</button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.negativeColor" routerLink="/vouchers" style="margin-left:30px;color:white">{{ configServise.translationJSON.cancel }}</button>
    </div>

    <mat-tab-group *ngIf="update">
        <mat-tab style="margin-left: 50px;" label="Upravljanje vrednostima vendora">
            <div style="padding:30px">
                <app-vouchers-amounts></app-vouchers-amounts>
            </div>
        </mat-tab>

        <mat-tab label="Gift kartice">
            <app-qr-barcode-codes [vouchers_id]="update" [showVendorMatSelect]="false"></app-qr-barcode-codes>
        </mat-tab>
    </mat-tab-group>
</mat-card>
