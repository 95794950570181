import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  configService;
  data: any = [];


  constructor(configService: ConfigService, private https: HttpClient, private router: Router) { 
    this.configService = configService;
    
  } 

  ngOnInit() {
    this.configService.httpGet(this.configService.getAPI() + 'dashboard').subscribe(data => {
      this.data = data;
    });
  }

  navigate(item){
    if(item.navigate != ""){ 
      this.configService.path = item.navigate;
      this.router.navigate([item.navigate]);
    }
  }

}
