import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  configServise;
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, configServise: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.configServise = configServise;
     }

  ngOnInit() {
  }

  ok(): void {
    this.dialogRef.close();
  }

}
