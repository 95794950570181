import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-answer-message',
  templateUrl: './answer-message.component.html',
  styleUrls: ['./answer-message.component.css']
})
export class AnswerMessageComponent implements OnInit {
  data: any = { };
  update:any = null;
  file: any;
  file2: any;
  validForm = true;
  configServise;
  user_name:any = [];

  constructor(private https: HttpClient, configServise: ConfigService, private router: Router,public dialog:MatDialog , private route: ActivatedRoute, public datepipe: DatePipe) {
    this.configServise = configServise; 
  }

  ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    if(this.update){
      this.configServise.httpGet(this.configServise.getAPI() + 'messages/' + this.update).subscribe(data => {
        this.data = data;
      });
    }
  }

  openAlert(): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: "Odgovorite na pitanje" }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result && result.agree) {

      }
    });
  }

  validateForm() {
    if (this.data.answer == "" || this.data.answer == null || this.data.answer == undefined) {
      this.openAlert();
      return false
    }
    return true
  }

  answerMessage(){
    let body = { id: this.update, message: this.data.message, answer: this.data.answer, user_name: this.data.user_name};
    this.configServise.httpPost(this.configServise.getAPI() + 'messages/{id}/answer_message', body).subscribe(data => {
      let tmp: any = data;
      if(tmp.result == true){
        this.router.navigate(['messages']);
      }
    });
  }

  checkLength(event){
    if(event.keyCode == 69 || event.srcElement.value.length > 1 && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))){
      event.preventDefault();
    };
  }

}
