<mat-card style="display:inline-block;margin:4px 0;width:380px;vertical-align: top;background-color: transparent;padding:10px;">
  <input #file type="file" style="display:none"
      id="file"
      (change)="fileSelected($event)">
  <button *ngIf="path == null" [style.background-color]="configService.darkColor" mat-raised-button (click)="file.click()" style="border-radius: 15px;color: black"><mat-icon style="padding-left:2px">attach_file</mat-icon>{{ configService.translationJSON.add_file }}</button>
  <button *ngIf="path != null" color="warn" mat-mini-fab (click)="clearInput();" style="border-radius: 15px;color: white"><mat-icon style="padding-left:2px">delete_forever</mat-icon></button>
  <a [href]="path" target="_blank" style="margin:0; margin-right:20px;margin-left:20px;display:inline-block;vertical-align: top;margin-top:10px">
    {{ path?.split("/")[this.path.split("/").length - 1] }}
  </a>
  <img *ngIf="path && !video" style="margin-top:5px;width:375px;display:block" [src]="path" />  
  <div *ngIf="path && video" style="margin-top:5px;width:375px;display:block" >
    <vg-player>
        <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-time-display></vg-time-display>
    
            <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
    
            <vg-fullscreen></vg-fullscreen>
        </vg-controls>
    
        <video #myVideoRef
                id="myVideo"
                [src]="path"
                [vgMedia]="myVideoRef">
    
        </video>
    </vg-player>
</div>
</mat-card>