import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpClient } from '@angular/common/http';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {
  configServise;
  hardcodedVidljivost;
  hardcodedVidljivost_with_0_trainings;
  hardcodedVidljivost_with_0_sales;
  data: any = { lista_korisnika: 0, vidljivost: {}};
  cntUsers = 0;

  constructor(configServise: ConfigService, private dialog:MatDialog,private https: HttpClient) { 
    this.configServise = configServise;
  }

  ngOnInit() {
    this.configServise.httpGet(this.configServise.getAPI() + 'gettree').subscribe(data => {
      this.data.vidljivost = data;
      this.hardcodedVidljivost = JSON.parse(JSON.stringify(data));
    });
    this.configServise.httpGet(this.configServise.getAPI() + 'gettree_with_0_trainings').subscribe(data => {
      this.hardcodedVidljivost_with_0_trainings = JSON.parse(JSON.stringify(data));
    });
    this.configServise.httpGet(this.configServise.getAPI() + 'gettree_with_0_sales').subscribe(data => {
      this.hardcodedVidljivost_with_0_sales = JSON.parse(JSON.stringify(data));
    });
  }
  toggleExpanded(item){
    item.expanded = ~item.expanded;
  }
  promeniVidljivost(){
    this.data.vidljivost.svi = 1;
    this.odaberiSve();
    this.data.vidljivost.svi = 0;
    if(this.data.lista_korisnika == 0) this.data.vidljivost = this.hardcodedVidljivost;
    if(this.data.lista_korisnika == 1) this.data.vidljivost = this.hardcodedVidljivost_with_0_sales;
    if(this.data.lista_korisnika == 2) this.data.vidljivost = this.hardcodedVidljivost_with_0_trainings;
    
    this.cntUsers = 0;
  }
  odaberiSve(){
    if(!this.data.vidljivost.svi){
      this.data.vidljivost.child.forEach(element => {
        element.checked = true;
        element.cntselected = element.child.length;
        element.child.forEach(element2 => {
          element2.checked = true;
          element2.users.forEach(element3 => {
            if(element3.devices.length > 0) {
              element3.checked = true;
              element2.cntselected++
            }
          });
        });
      });
    }
    else {
      this.data.vidljivost.child.forEach(element => {
        element.checked = false;
        element.cntselected = 0;
        element.child.forEach(element2 => {
          element2.checked = false;
          element2.cntselected = 0;
          element2.users.forEach(element3 => {
              element3.checked = false;
          });
        });
      });
    }
    this.izracunajKorisnike();
  }
  odaberi3(item, item2, item3){
    if(item3.devices.length > 0) {
      if(item3.checked){
        item2.cntselected--;
        this.cntUsers--;
      }
      else {
        item2.cntselected++;
        this.cntUsers++;
      }
      let all = true;
      let all2 = true;
      let all3 = true;
      item2.users.forEach(element => {
        if(element.devices.length > 0 && (element == item3 && element.checked || element != item3 && !element.checked)) all3 = false;
      });
      item2.checked = all3;
      let cnt2 = 0;
      item.child.forEach(element => {
        if(!element.checked && element.users.length > 0) all2 = false;
        if(!element.checked) null;
        else cnt2++;
      });
      item.checked = all2;
      item.cntselected = cnt2;
      this.data.vidljivost.child.forEach(element => {
        if(!element.checked) all = false;
      });
      this.data.vidljivost.svi = all;
    }
  }
  odaberi2(item, item2){
    if(!item2.cntselected) item2.cntselected = 0; 
    if(!item.cntselected) item.cntselected = 0; 
    if(item2.users.length > 0) {
      if(item2.checked){
        item.cntselected--;  
        item2.users.forEach(element => {
          element.checked = false;
        });
      }
      else {
        item.cntselected++;    
        item2.users.forEach(element => {
          if(element.devices.length > 0) {
            element.checked = true;
            item2.cntselected++
          }
        });
      }
      let all = true;
      let all2 = true;
      item.child.forEach(element => {
        if(element == item2 && element.checked || element != item2 && !element.checked && element.users.length > 0) all2 = false;
      });
      item.checked = all2;
      this.data.vidljivost.child.forEach(element => {
        if(!element.checked) all = false;
      });
      this.data.vidljivost.svi = all;
      this.izracunajKorisnike();
    }
  }
  odaberi(item){
    let all = true;
    this.data.vidljivost.child.forEach(element => {
      if(element == item && element.checked || element != item && !element.checked) all = false;
    });
    this.data.vidljivost.svi = all;
    if(!item.checked){
      item.cntselected = 0;
      item.child.forEach(element => {
        element.checked = true;
        item.cntselected++;
        element.users.forEach(element2 => {
          if(element2.devices.length > 0) {
            element2.checked = true;
            element.cntselected++
          }
        });
      });
    }
    else {
      item.cntselected = 0;
      item.child.forEach(element => {
        element.checked = false;
        element.users.forEach(element2 => {
            element.cntselected = 0;
            element2.checked = false
        });
      });
    }
    this.izracunajKorisnike();
  }
  izracunajKorisnike(){
    this.cntUsers = 0;
    this.data.vidljivost.child.forEach(element => {
      element.child.forEach(element2 => {
        element2.users.forEach(element3 => {
          if(element3.checked) this.cntUsers++;
        });
      });
    });
  }

  sendPush(){
    if(this.validateForm()) {
    let arr = [];
    let users = [];
    this.data.vidljivost.child.forEach(element => {
      element.child.forEach(element2 => {
        element2.users.forEach(element3 => {
          if(element3.checked) {
            element3.devices.forEach(device => {
              arr.push(device.push_token);
            });
            users.push(element3.id);
          }
        });
      });
    });
    let body = {
      users: users,
      push_token: arr,
      push_title: this.data.naslov,
      push_message: this.data.text,
      push_type: 1,
      push_item_id: 1
    }
    this.configServise.httpPost(this.configServise.getAPI() + 'push', body).subscribe(data => {
      this.configServise.httpPost(this.configServise.getAPI() + 'logpush', this.data).subscribe(data => {   
        alert(this.configServise.translationJSON.successfully_sent);
        this.ngOnInit();
      });
    });
  }
  }

  validateForm() {

    if (this.cntUsers == 0) {
      this.openAlert(this.configServise.translationJSON.choose_a_user + "!");
      return false
    }
    if (this.data.naslov == "" || this.data.naslov == null || this.data.naslov == undefined) {
      this.openAlert(this.configServise.translationJSON.enter_a_title + "!");
      return false
    }
    if(this.data.text == 0 || this.data.text == null || this.data.text == undefined) {
      this.openAlert(this.configServise.translationJSON.enter_text + "!");
      return false
    }
    return true;
    
  }
  openAlert(msg): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result && result.agree) {

      }
    });
  }

}
