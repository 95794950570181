<div style="margin:10px">
    <h2 style="text-align:center">
        <span *ngIf="update"> Pregled kupovine</span>
        <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="goback()"> Sve kupovine </button>
    </h2>
</div>
<mat-card>
    
    <div>
        <p style="font-weight:bold;margin-right:20px">{{ configServise.translationJSON.user }}: </p>
        <p>{{ data.user_name }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Status: </p>
        <p>{{ data.status }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px"> Broj telefona </p>
        <p>{{ data.phone }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Cash Back: </p>
        <p>{{ data.cash_back }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px"> Vendor: </p>
        <p>{{ data.voucher_name }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px"> Iznos gift kartice: </p>
        <p>{{ data.value }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Tip koda: </p>
        <p>{{ data.type }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Kod: </p>
        <p>{{ data.code }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Datum kupovine:</p>
        <p>{{ data.date }}</p>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Landing page:</p>
        <a [href]="data.landing_link" target="_blank">go to link</a>
    </div>

    <div>
        <p style="font-weight:bold;margin-right:20px">Code image</p>
        <img [src]="data.icon_code" />
    </div>

</mat-card>
