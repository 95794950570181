
<div class="mat-elevation-z8" style="width:80%;margin:20px auto">
    <table #smstable mat-table class="full-width-table" style="width:100%;margin:auto" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.id }} </th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.action }} </th>
        <td mat-cell *matCellDef="let row">{{row.event}}</td>
    </ng-container>

    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.message }} </th>
        <td mat-cell *matCellDef="let row">{{row.message}}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.time }} </th>
        <td mat-cell *matCellDef="let row">{{row.created_at}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 25, 50, 100, 250]">
    </mat-paginator>
</div>
   