import { DatePipe, Location  } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-review-gift',
  templateUrl: './review-gift.component.html',
  styleUrls: ['./review-gift.component.css']
})
export class ReviewGiftComponent implements OnInit {
  data: any = { };
  update:any = null;
  file: any;
  file2: any;
  validForm = true;
  configServise;

  constructor(private _location: Location,private https: HttpClient, configServise: ConfigService, private router: Router,public dialog:MatDialog , private route: ActivatedRoute, public datepipe: DatePipe) {
    this.configServise =configServise;
   }

   ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    if(this.update){
      this.configServise.httpGet(this.configServise.getAPI() + 'gifts/' + this.update).subscribe(data => {
        this.data = data;
      });
    }
  }

  goback(){
    this._location.back();
  }

}
