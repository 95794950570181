import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  configServise;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, configServise: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.configServise = configServise;
     }

  ngOnInit() {
    console.log(this.data);
  }

  da(){
    this.dialogRef.close({ agree: true });
  }

  ne(): void {
    this.dialogRef.close({ agree: false });
  }

}
