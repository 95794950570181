<h2 style="text-align:center">
    {{ configServise.translationJSON.push_notifications }}
</h2>
<div style="height:15px"></div>
<h2 style="margin-left:20px"> {{ configServise.translationJSON.select_a_user_list }} </h2>
<div style="margin-left:40px;margin-bottom: 40px;">
    <mat-radio-group [(ngModel)]="data.lista_korisnika" (change)="promeniVidljivost()" name="radio" style="display:block;margin-bottom:15px;margin-top:15px">
        <mat-radio-button name="radio" [value]="0" style="display: block;"> {{ configServise.translationJSON.all_users }} </mat-radio-button>
        <mat-radio-button name="radio" [value]="1" style="display: block;"> {{ configServise.translationJSON.users_without_login }} </mat-radio-button>
        <mat-radio-button name="radio" [value]="2" style="display: block;"> {{ configServise.translationJSON.users_without_training }} </mat-radio-button>
    </mat-radio-group>
</div> 

<div style="margin-left:40px">
    <mat-checkbox [(ngModel)]="data.vidljivost.svi" color="primary" (click)="odaberiSve()"> {{ configServise.translationJSON.all }} </mat-checkbox>
    <div>
        <div *ngFor="let item of data.vidljivost.child">
            <mat-icon *ngIf="!item.expanded" (click)="toggleExpanded(item)" class="arrowToggle">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="item.expanded" (click)="toggleExpanded(item)" class="arrowToggle">keyboard_arrow_down</mat-icon>
            <mat-checkbox [(ngModel)]="item.checked" color="primary" (click)="odaberi(item)" style="vertical-align: top;margin-top:2px;">{{ item.name }} ({{ item.cntselected }})</mat-checkbox>
            
            <div *ngIf="item.expanded" style="margin-left:50px">
                <div *ngFor="let item2 of item.child">
                    <div style="width:24px;display: inline-block;">
                        <mat-icon *ngIf="!item2.expanded && item2.users.length > 0" (click)="toggleExpanded(item2)" class="arrowToggle">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="item2.expanded" (click)="toggleExpanded(item2)" class="arrowToggle">keyboard_arrow_down</mat-icon>
                    </div>
                    <mat-checkbox [(ngModel)]="item2.checked" color="primary" (click)="odaberi2(item, item2)" style="vertical-align: top;margin-top:2px;" [disabled]="item2.users.length == 0">{{ item2.name }} {{ item2.users.length == 0?' (nema korisnike)':'' }}</mat-checkbox>
                    
                    <div *ngIf="item2.expanded" style="margin-left:50px">
                        <div *ngFor="let item3 of item2.users">
                            <mat-checkbox [(ngModel)]="item3.checked" color="primary" (click)="odaberi3(item, item2, item3)" style="vertical-align: top;margin-top:2px;" [disabled]="item3.devices.length == 0">{{ item3.name }} {{ item3.devices.length == 0?' - nedostupan korisnik':'' }}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div style="height:30px"></div>
<h3 style="margin-left:20px"> {{ configServise.translationJSON.selected }}  {{ cntUsers }} {{ configServise.translationJSON.user_ }}</h3>
<div style="height:40px"></div>
<mat-form-field style="width:50%;margin:30px;display:block">
    <input matInput [(ngModel)]="data.naslov" maxlength="150" [placeholder]="configServise.translationJSON.title" />
</mat-form-field>
<mat-form-field style="width:50%;margin:30px;display:block">
    <textarea matInput [(ngModel)]="data.text" maxlength="292" [placeholder]="configServise.translationJSON.text" style="height:100px"></textarea>
</mat-form-field>

<button mat-raised-button [style.background-color]="configServise.color" style="color:white;margin:30px" (click)="sendPush()"> {{ configServise.translationJSON.send }} </button>