import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  configService;
  constructor(private breakpointObserver: BreakpointObserver, private changeDetectorRefs: ChangeDetectorRef, configService: ConfigService, 
              private route: ActivatedRoute, private router: Router, private https: HttpClient) {
    this.configService = configService;
  }

  ngOnInit() {    
    this.route.params.subscribe(params => {      
      this.configService.httpGet(this.configService.getAPI() + 'my-gift/' + params['id']).subscribe(data => {
        this.configService.giftForShow = data;
      });
    });
  }

}
