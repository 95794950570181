<div *ngIf="users_id == null" style="margin:10px">
    <h2 style="text-align:center">
      Kupovine
    <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="openPopup()"><mat-icon>subject</mat-icon>{{ configServise.translationJSON.report }}</button>
    </h2>
</div>
<div *ngIf="users_id != null" style="height: 40px;"></div>
  
  <div class="mat-elevation-z8" style="width:90%;margin:auto">
    <mat-form-field style="margin:20px;width:350px">
      <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="currFilterValue" [placeholder]="'Korisnik'">
    </mat-form-field>

    <mat-form-field style="margin:20px;width:150px;vertical-align: top;" >
      <mat-select [(value)]="vouchers_id" placeholder="Vendor" (selectionChange)="applyFilter();getVouchersAmountsByVoucherID(vouchers_id)">
        <mat-option [value]="'all'">{{ configServise.translationJSON.all }}</mat-option>
        <mat-option *ngFor="let item of vouchers" [value]="item.id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="margin:20px;width:150px;vertical-align: top;" >
      <mat-select [(value)]="vouchers_amounts_value" placeholder="Vrednost" (selectionChange)="applyFilter()">
        <mat-option [value]="'all'">{{ configServise.translationJSON.all }}</mat-option>
        <mat-option *ngFor="let item of vouchers_amounts" [value]="item.value">{{ item.value }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button [style.background-color]="configServise.color" style="color:white; margin: 20px; width:120px" (click)="ResetFilter()">Reset filter</button>
    
      <table mat-table class="full-width-table" matSort aria-label="Elements"> 
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.id }} </th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>
  
        <ng-container matColumnDef="user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Korisnik </th>
          <td mat-cell *matCellDef="let row">
            <a [routerLink]="['/user']" [queryParams]="{id: row.user_id}" target="_blank">{{row.user_name}}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Broj telefona </th>
          <td mat-cell *matCellDef="let row">{{row.phone}}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let row">{{row.status}}</td>
          </ng-container>-->
    
        <ng-container matColumnDef="cash_back">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cash back </th>
            <td mat-cell *matCellDef="let row">{{row.cash_back}}</td>
        </ng-container>

        <ng-container matColumnDef="vouchers_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor </th>
            <td mat-cell *matCellDef="let row">{{row.voucher_name}}</td>
        </ng-container>
  
        <ng-container matColumnDef="vouchers_amounts_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Iznos gift kartice </th>
              <td mat-cell *matCellDef="let row">{{row.value}}</td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip </th>
            <td mat-cell *matCellDef="let row">{{row.type}}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum kupovine </th>
          <td mat-cell *matCellDef="let row">{{row.date | date:'dd-MM-yyyy'}}</td>
        </ng-container>
      
        <ng-container matColumnDef="landing_link">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Landing page </th>
              <td mat-cell *matCellDef="let row">
                <a [href]="row.landing_link" target="_blank">link</a>
              </td>
        </ng-container>

  
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width:200px;"></th>
          <td mat-cell *matCellDef="let row" style="width:200px; text-align: center;">
              <button mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="gotoReviewGift(row.id)">Pregled</button>
              <button  mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="openDialog1(row.id)"> {{ configServise.translationJSON.delete }} </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
      <mat-paginator #paginator *ngIf="doneLoading"
        [length]="length"
        [(pageIndex)]="pageIndex"
        [(pageSize)]="pageSize"
        [pageSizeOptions]="[3, 15, 25, 50, 100, 250]"
        (page)="onPaginateChange($event)">
      </mat-paginator>
</div>
<div *ngIf="popup"  style="width:100%;height:100%;background-color:rgba(0, 0, 0, 0.7);z-index:9999;position:fixed;top:0;left:0;">
  <div style="position:fixed;min-height:400px;min-width:500px;background-color:white;top:17%;left:35%">
      <h1 style="font-style:italic;font-weight:bold;text-align:center">{{ configServise.translationJSON.report_per_user }}</h1>
      <!--<h2 style="text-align:center">Poslato: {{ cnt }} / {{ users.length }}</h2>
      <mat-progress-spinner
          style="margin-left:calc(50% - 50px)"
          class="example-margin"
          [color]="configServise.color"
          mode="determinate"
          [value]="(cnt / users.length) * 100">
      </mat-progress-spinner>
      <h2 style="text-align:center">{{ (cnt / users.length) * 100 | number:'1.0-0' }} %</h2>
      <p style="text-align:center;margin-top:10px">Molimo Vas sačekajte,<br/>i nemojte zatvarati ovaj prozor.</p>-->
      <div style="margin:30px 60px;padding: 40px;padding-top:15px;margin-bottom:0">          
        <div style="display:table;margin:auto;margin-bottom:20px;">     
          <mat-radio-group [(ngModel)]="dataIzvestaj.tip" (change)="alert = false" name="radio" style="display:block;margin-bottom:15px;margin-top:15px">
              <mat-radio-button name="radio" [value]="0" style="margin-right:50px">{{ configServise.translationJSON.by_date }}</mat-radio-button>
              <mat-radio-button name="radio" [value]="1">{{ configServise.translationJSON.up_to_now }}</mat-radio-button>
          </mat-radio-group> 
        </div>
        <div style="display:table;margin:auto;;min-height:66px">     
          <p *ngIf="!dataIzvestaj.tip" style="display:inline-block;font-weight:bold;margin-right:30px">{{ configServise.translationJSON.from }}: </p>
          <mat-form-field *ngIf="!dataIzvestaj.tip">
              <input matInput [matDatepicker]="picker" [(ngModel)]="dataIzvestaj.od" placeholder="MM/DD/YYYY" [ngModelOptions]="{timezone: 'UTC'}">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>          
        </div>
        <div style="display:table;margin:auto;min-height:66px">
          <p *ngIf="!dataIzvestaj.tip" style="display:inline-block;font-weight:bold;margin-right:30px;">{{ configServise.translationJSON.to }}: </p>
          <mat-form-field *ngIf="!dataIzvestaj.tip">
              <input matInput [matDatepicker]="picker2" [(ngModel)]="dataIzvestaj.do" placeholder="MM/DD/YYYY">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div style="height: 30px;">
        <p *ngIf="alert" style="font-weight:bold;margin:0px;text-align: center;" [style.color]="configServise.color">{{ alertText }}</p>
      </div>
      <div style="margin-bottom:30px">
        <div style="display:inline-block;width:50%">
          <div style="display:table;margin:auto;">
            <img *ngIf="waiting" src="assets/loading-png-gif-original.gif" style="width:30px" />
            <button *ngIf="!waiting" mat-raised-button [style.background-color]="configServise.color" style="color:white" (click)="generisiExcel()"><mat-icon>check</mat-icon>{{ configServise.translationJSON.generate }}</button>
          </div>
        </div>
        <div style="display:inline-block;width:50%">
          <div style="display:table;margin:auto;">
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color:white" (click)="closePopup()"><mat-icon>clear</mat-icon>{{ configServise.translationJSON.back }}</button>
          </div>
        </div>
      </div>
  </div>
</div>
