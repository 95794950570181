import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit, AfterViewInit {
  configServise;
  
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('smstable', {static: false}) table: MatTable<any>;
  dataSource: any = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'event', 'message', 'created_at'];


  constructor(private https: HttpClient, configServise: ConfigService,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
      this.configServise = configServise;
  }

  ngOnInit() { 
  }
  
  ngAfterViewInit() {  
    this.dataSource = new MatTableDataSource();  
    this.configServise.httpGet(this.configServise.getAPI() + 'logs/SMS').subscribe(data => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = <Array<any>> data;
      this.table.dataSource = this.dataSource;
    });
  }
  tabchanged(e){

  }

}
