<div style="margin:10px">
    <h2 style="text-align:center">
        <span *ngIf="!update"> Dodaj gift karticu </span>
        <span *ngIf="update"> Izmeni gift karticu</span>
        <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="goback()">>Sve gift kartice</button>
    </h2>
</div>
<mat-card>
    <p>QR/Barcode</p>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="data.type" style="display: block;padding-left:30px;margin-bottom:25px;">
        <mat-radio-button [value]="'QRcode'" style="margin-right:70px">QR</mat-radio-button>
        <mat-radio-button [value]="'Barcode'" >Barcode</mat-radio-button>
    </mat-radio-group>

    <mat-form-field style="width:50%;display:block">
        <input matInput [(ngModel)]="data.code" placeholder="Code">
    </mat-form-field>

    <mat-form-field style="display: block;width:31%">        
        <mat-select matInput [(ngModel)]="data.vouchers_id"  style="background-color: transparent;" placeholder="Vaucer" (selectionChange)="onVoucherChange($event)">
          <mat-option *ngFor="let item of vouchers" [value]="item.id">{{ item.name }}</mat-option>        
        </mat-select>
    </mat-form-field>

    <mat-form-field style="display: block;width:31%">        
        <mat-select matInput [(ngModel)]="data.vouchers_amounts_id"  style="background-color: transparent;" placeholder="Vrednost vaucera">
          <mat-option *ngFor="let item of vouchers_amounts" [value]="item.id">{{ item.value }}</mat-option>        
        </mat-select>
    </mat-form-field>

   <!-- <div>
    <button *ngIf="!update" mat-raised-button [style.background-color]="configServise.color" style="color: white; margin-bottom: 20px;" (click)="generate_QR_Barcode()">Generate QR/Barcode</button>
   </div>-->

    <div *ngIf="update">
        <img [src]="data.icon" />
    </div>

    <div style="margin-top: 50px">
        <button *ngIf="!update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="addQR_Barcode()"> {{ configServise.translationJSON.add }} </button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="updateQR_Barcode()"> {{ configServise.translationJSON.save }} </button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.negativeColor"  routerLink="/qr-barcode-codes" style="margin-left:30px;color:white"> {{ configServise.translationJSON.cancel }} </button>
    </div>
</mat-card>
