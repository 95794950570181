<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="'side'"
      [opened]="opened && configService.token != null && !my_gift"
      style="width:220px;
              height: calc(100vh - 40px);">
    <mat-toolbar style="color:black;z-index:999;position:absolute" [style.background-color]="configService.color">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawertoggle();opened = false">
        <mat-icon style="color:black;" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      Menu
    </mat-toolbar>
    <div style="height:63px"></div>
    <mat-nav-list id="scrollable" style="padding-left:10px; height:calc(100% - 72px);overflow: auto;" [style.background-color]="configService.lightcolor">
      <div *ngIf="configService.me.admin">
        <a *ngIf="configService.me.admin.dashboard" mat-list-item routerLink="dashboard" [style.color]="configService.path == 'dashboard'?configService.negativeColor:'gray'" (click)="setPath('dashboard')"><mat-icon style="margin-right:5px">dashboard</mat-icon>Dashboard</a>
        <p *ngIf="configService.me.admin.companies
                || configService.me.admin.shops
                || configService.me.admin['users-requests']
                || configService.me.admin.users
                || configService.me.admin.administration" style="margin:0;margin-top:5px;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.users }}</p>
        <a *ngIf="configService.me.admin.companies" mat-list-item routerLink="companies" [style.color]="configService.path == 'companies'?configService.color:'gray'" (click)="setPath('companies')"><mat-icon style="margin-right:5px">account_balance</mat-icon>{{ configService.translationJSON.customers }}</a>
        <a *ngIf="configService.me.admin.shops" mat-list-item routerLink="shops" [style.color]="configService.path == 'shops'?configService.color:'gray'" (click)="setPath('shops')"><mat-icon style="margin-right:5px">shopping_cart</mat-icon>{{ configService.translationJSON.business_units }}</a>
        <a *ngIf="configService.me.admin['users-requests']" mat-list-item routerLink="users-requests" [style.color]="configService.path == 'users-requests'?configService.color:'gray'" (click)="setPath('users-requests')"><mat-icon style="margin-right:5px">supervised_user_circle</mat-icon>{{ configService.translationJSON.requests }}</a>
        <a *ngIf="configService.me.admin.users" mat-list-item routerLink="users" [style.color]="configService.path == 'users'?configService.negativeColor:'gray'" (click)="setPath('users')"><mat-icon style="margin-right:5px">person</mat-icon>{{ configService.translationJSON.users }}</a>
        <a *ngIf="configService.me.admin.administration" mat-list-item routerLink="administration" [style.color]="configService.path == 'administration'?configService.negativeColor:'gray'" (click)="setPath('administration')"><mat-icon style="margin-right:5px">admin_panel_settings</mat-icon>{{ configService.translationJSON.administration }}</a>
  
        
        <p *ngIf="configService.me.admin.terms" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.rules }}</p>
        <a *ngIf="configService.me.admin.terms" mat-list-item routerLink="terms" [style.color]="configService.path == 'terms'?configService.color:'gray'" (click)="setPath('terms')"><mat-icon style="margin-right:5px">policy</mat-icon>{{ configService.translationJSON.terms_of_use }}</a>

        <p *ngIf="configService.me.admin.postnews
                || configService.me.admin.postpromotions" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.publications }}</p>
        <a *ngIf="configService.me.admin.postnews" mat-list-item routerLink="postnews" [style.color]="configService.path == 'postnews'?configService.color:'gray'" (click)="setPath('postnews')"><mat-icon style="margin-right:5px">menu_book</mat-icon>{{ configService.translationJSON.news }}</a>
        <a *ngIf="configService.me.admin.postpromotions" mat-list-item routerLink="postpromotions" [style.color]="configService.path == 'postpromotions'?configService.color:'gray'" (click)="setPath('postpromotions')"><mat-icon style="margin-right:5px">local_offer</mat-icon>{{ configService.translationJSON.promotions }}</a>
  
        <p *ngIf="configService.me.admin.prizearticals
                || configService.me.admin.vouchers
                || configService.me.admin.bougtharticals" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.gifts }}</p>
        <a *ngIf="configService.me.admin.categories" mat-list-item routerLink="categories" [style.color]="configService.path == 'categories'?configService.negativeColor:'gray'" (click)="setPath('categories')"><mat-icon style="margin-right:5px">category</mat-icon>{{ configService.translationJSON.categories }}</a>
        <a *ngIf="configService.me.admin.vouchers" mat-list-item routerLink="vouchers" [style.color]="configService.path == 'vouchers'?configService.negativeColor:'gray'" (click)="setPath('vouchers')"><mat-icon style="margin-right:5px">label_important</mat-icon>Vendori</a>
        <a *ngIf="configService.me.admin['qr-barcode-codes']" mat-list-item routerLink="qr-barcode-codes" [style.color]="configService.path == 'qr-barcode-codes'?configService.negativeColor:'gray'" (click)="setPath('qr-barcode-codes')"><mat-icon style="margin-right:5px">credit_card</mat-icon>Gift kartice</a>
        <a *ngIf="configService.me.admin['gifts']" mat-list-item routerLink="gifts" [style.color]="configService.path == 'gifts'?configService.negativeColor:'gray'" (click)="setPath('gifts')"><mat-icon style="margin-right:5px">card_giftcard</mat-icon>Kupovine</a>
        <a *ngIf="false" mat-list-item routerLink="messages" [style.color]="configService.path == 'messages'?configService.negativeColor:'gray'" (click)="setPath('messages')"><mat-icon style="margin-right:5px">message</mat-icon>Messages</a>
        <a *ngIf="configService.me.admin.terms_and_conditions" mat-list-item routerLink="terms_and_conditions" [style.color]="configService.path == 'terms_and_conditions'?configService.negativeColor:'gray'" (click)="setPath('terms_and_conditions')"><mat-icon style="margin-right:5px">info</mat-icon>{{ configService.translationJSON.terms_and_conditions }}</a>
        <a *ngIf="configService.me.admin.prizearticals" mat-list-item routerLink="prizearticals" [style.color]="configService.path == 'prizearticals'?configService.color:'gray'" (click)="setPath('prizearticals')"><mat-icon style="margin-right:5px">card_giftcard</mat-icon>{{ configService.translationJSON.gifts }}</a>
        <a *ngIf="configService.me.admin.bougtharticals" mat-list-item routerLink="bougtharticals" [style.color]="configService.path == 'bougtharticals'?configService.color:'gray'" (click)="setPath('bougtharticals')"><mat-icon style="margin-right:5px">assignment_turned_in</mat-icon>{{ configService.translationJSON.ordered_gifts }}</a>

        <p *ngIf="configService.me.admin.products
                 || configService.me.admin.sales" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.articles }}</p>
        <a *ngIf="configService.me.admin.products" mat-list-item routerLink="products" [style.color]="configService.path == 'products'?configService.color:'gray'" (click)="setPath('products')"><mat-icon style="margin-right:5px">bubble_chart</mat-icon>{{ configService.translationJSON.products }}</a>
        <a *ngIf="configService.me.admin.sales" mat-list-item routerLink="sales" [style.color]="configService.path == 'sales'?configService.color:'gray'" (click)="setPath('sales')"><mat-icon style="margin-right:5px">move_to_inbox</mat-icon>{{ configService.translationJSON.sales_applications }}</a>
  
        <p *ngIf="configService.me.admin.baneri" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.banners }}</p>
        <a *ngIf="configService.me.admin.baneri" mat-list-item routerLink="baneri" [style.color]="configService.path == 'baneri'?configService.color:'gray'" (click)="setPath('baneri')"><mat-icon style="margin-right:5px">add_photo_alternate</mat-icon>{{ configService.translationJSON.banners }}</a>
      
        <p *ngIf="configService.me.admin.faq" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.faq }}</p>
        <a *ngIf="configService.me.admin.faq" mat-list-item routerLink="faq" [style.color]="configService.path == 'faq'?configService.color:'gray'" (click)="setPath('faq')"><mat-icon style="margin-right:5px">question_answer</mat-icon>{{ configService.translationJSON.frequently_questions }}</a>
        
        <p *ngIf="configService.me.admin.notifications
                || configService.me.admin['push-notifications']" style="margin:0;font-size:10pt;font-weight: bold;text-transform: uppercase;">{{ configService.translationJSON.notifications }}</p>
        <a *ngIf="configService.me.admin.notifications" mat-list-item routerLink="notifications" [style.color]="configService.path == 'notifications'?configService.color:'gray'" (click)="setPath('notifications')"><mat-icon style="margin-right:5px">notifications</mat-icon>{{ configService.translationJSON.sms }}</a>
        <a *ngIf="configService.me.admin['push-notifications']" mat-list-item routerLink="push-notifications" [style.color]="configService.path == 'push-notifications'?configService.color:'gray'" (click)="setPath('push-notifications')"><mat-icon style="margin-right:5px">system_update</mat-icon>{{ configService.translationJSON.push }}</a>
    

        <p *ngIf="configService.me.admin.logs" style="margin:0;font-size:10pt;font-weight: bold;">{{ configService.translationJSON.logs }}</p>
        <a *ngIf="configService.me.admin.logs" mat-list-item routerLink="logs" [style.color]="configService.path == 'logs'?configService.color:'gray'" (click)="setPath('logs')"><mat-icon style="margin-right:5px">view_list</mat-icon>{{ configService.translationJSON.logs }}</a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [style.margin-left]="!opened || !configService.token || my_gift ? '0':'220px'">
    <mat-toolbar style="z-index: 200;" color="primary" [style.background-color]="configService.color" 
                  [style.height]="my_gift ? '75px' : ''"
                  [style.padding-left]="my_gift ? 'calc(50% - 500px)' : ''"
                  [style.padding-right]="my_gift ? 'calc(50% - 500px)' : ''">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawertoggle(); opened = true"
        *ngIf="!opened && configService.token != null && !my_gift">
        <mat-icon style="color:black;" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img src="assets/cardian_logo.png" style="height:44px" class="headerLogoMobile" />
      <p style="width:100%" *ngIf="configService.token != null"></p>
      <p style="text-align:center;width:100%;padding-right: 124.3px;" [style.color]="configService.negativeColor" *ngIf="configService.token == null && !my_gift">Cardian Administration</p>
      <p *ngIf="my_gift" style="text-align:right;width:100%;font-size:22pt;font-weight:bold" [style.color]="configService.negativeColor">{{ configService.giftForShow.value }}</p>
      <p *ngIf="my_gift" style="font-size:13pt;vertical-align:top;display:inline-block;margin-top:20px;font-weight:bold;margin-left:5px;margin-right: 70px;" class="headerValuteMobile" [style.color]="configService.negativeColor">RSD</p>
      <div *ngIf="!my_gift" style="float:right">
        <div style="display:inline-block">
          <p style="margin:0;text-align: center;transform: translateY(12px); color: black;">{{ configService.me?.name }}</p>
          <p style="margin:0;font-size:8pt;text-align: center; color: black;">{{ configService.country_name }}</p>
        </div>
        <button *ngIf="configService.token" mat-icon-button [matMenuTriggerFor]="menu" style="vertical-align: top;margin-top:10px;">
            <mat-icon style="color: black;" >power_settings_new</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>{{ configService.translationJSON.logout }}</span>
        </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div style="padding-top:0;margin-bottom:56px" [style.padding.px]="configService.token != null?'10':'0'">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
