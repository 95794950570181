import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css']
})
export class VouchersComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  dataSource: any = [];
  all_vouchers: any = [];
  configServise;
  currFilterValue = '';

  constructor(private https: HttpClient, configServise: ConfigService, public dialog: MatDialog,
              private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
                this.configServise =configServise;
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'promotion', 'promotion_percent', 'cash_back_percent', 'action'];

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.configServise.httpGet(this.configServise.getAPI() + 'all_vouchers').subscribe(data => {
      this.all_vouchers = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = <Array<any>> data;
      this.table.dataSource = this.dataSource;
    });
  }

  deleteVoucher(id){
    //if(confirm("Da li ste sigurni da hoćete da obrišete vaučer?")){
      this.configServise.httpDelete(this.configServise.getAPI() + 'deletevoucher/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.dataSource.data = <Array<any>> data;
          this.changeDetectorRefs.detectChanges();
        }
        else {
          alert(tmp.text);
        }
      });
    //}
  }
  gotoEditVoucher(id){
    this.router.navigate(['voucher'], { queryParams: { id: id }});
  }

  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: this.configServise.translationJSON.are_you_sure_you_want_to_delete_the_voucher + "?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if(result && result.agree){
        this.deleteVoucher(id);
      }
    });
  }
  applyFilter(value){
    let array: any = [];
    if(this.currFilterValue != '') {
      this.all_vouchers.forEach(element => {
        if(element.name.toLowerCase().indexOf(this.currFilterValue.toLowerCase()) != -1) {
          array.push(element);
        }
      });
    }    
    else array = this.all_vouchers;
    this.dataSource.data = <Array<any>> array;
    this.table.dataSource = this.dataSource;
  }
}
