import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigService } from '../services/config.service';
// import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  file;
  icon;
  path = null;
  
  @Input() video;
  
  @Input() 
  get file_link() {
    return this.path;
  }

  set file_link(path) {
    this.path = path == "" ? null : path;
    this.file_linkChange.emit(this.path);
  }

  @Output() file_linkChange: EventEmitter<string> = new EventEmitter();
  
  @Output() callback: EventEmitter<string> = new EventEmitter();

  constructor(private https: HttpClient, private configService: ConfigService) {
   }

  ngOnInit() {
  
  }

  fileSelected(event){
    this.file = event.target.files[0]; //event.srcElement.files[0];
    let body:FormData = new FormData();
    body.append('file', this.file);
    this.configService.httpPost(this.configService.getAPI() + 'uploadfile', body)
      .subscribe(data => {
      let tmp: any = data;
      if(tmp.result != false){
        if(this.callback) this.callback.emit(tmp.result);
        this.file = tmp.result;
        let icon = tmp.result.split("/");
        if(icon.length > 0 && icon != "") {
          this.file_link = tmp.result;
        }
        else {
          this.file_link = null;
        }
      }
    });
  }

  
  clearInput(){
    this.file_link = null;
    this.icon = "";
  }
}
