import { Component, ComponentFactoryResolver, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { HttpClient } from "@angular/common/http";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ConfigService } from "src/app/services/config.service";

@Component({
    selector: 'import-dialog',
    templateUrl: 'import-dialog.component.html',
})
export class ImportDialog {
    loading = false;
    configService: any;
    file: any;
    dataImport: any = {};
    @ViewChild('file', { static: true }) fileInput: ElementRef;
    arrayBuffer: any;
    message = "";
    not_founded_prices: any = [];
    vouchers: any = [];
    vouchers_amounts: any = [];
    constructor(
        public dialogRef: MatDialogRef<ImportDialog>, configService: ConfigService,
        @Inject(MAT_DIALOG_DATA) public data: any, private https: HttpClient
    ) {
        this.configService = configService;
        this.vouchers = data.vouchers;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    
    addfile(event: any) {     
        this.loading = true;   
        this.file= event.target.files[0];     
        if(!this.file) {
            this.loading = false;
            return;
        }
        let fileReader = new FileReader();    
        fileReader.readAsArrayBuffer(this.file);     
        fileReader.onload = (e) => {    
            this.arrayBuffer = fileReader.result;    
            var data = new Uint8Array(this.arrayBuffer);    
            var arr = new Array();    
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
            var bstr = arr.join("");    
            var workbook = XLSX.read(bstr, {type:"binary"});    
            var first_sheet_name = workbook.SheetNames[0];    
            if(first_sheet_name.indexOf('hidden') != -1){
                first_sheet_name = workbook.SheetNames[1];    
            }
            var worksheet = workbook.Sheets[first_sheet_name];     
            var arraylist = XLSX.utils.sheet_to_json(worksheet, {header:1, defval: ""});  
            let self = this;
            setTimeout(function() {
              self.loading = false;
            }, 21000);
            this.configService.httpPost(this.configService.getAPI() + 'codes_imports', { data: this.dataImport, arraylist: arraylist }).subscribe((data: any) => {                
                if((<any>data).result != false) {
                    this.message = (<any>data).result;
                }
                else {
                    this.message = "Doslo je do greske.";
                }
                let self = this;
                setTimeout(function(){
                    self.loading = false
                }, 100);
            }, (error: any) => {
                this.message = "Doslo je do greske.";
            });           
            this.fileInput.nativeElement.value = "";
        }    
    } 
    chackImport(e: any){    
        if(!this.dataImport.companies_id || !this.dataImport.week || !this.dataImport.year) return;  
        let body = JSON.parse(JSON.stringify(this.dataImport));  
        body.week = body.week + ' - ' + body.year;
        this.configService.httpPost(this.configService.getAPI() + 'api/check_import', body).subscribe((data: any) => {
            
            if((<any>data).result == false){
                this.message = "Nema uvezenih podataka za odabranu nedelju i kupca.";
            }
            else {
                this.message = "Uvezeno " + (<any>data).result.created_at.split(" ")[0] + ".\n Ponovnim uvozom podataka, obrisace se prethodno uneti podaci.";
            }
        });
    }
    not_founded_prices_to_excel(){
        let arr:any = [];
        this.not_founded_prices.forEach((element: any) => {
            arr.push({
                EAN: element,
            });
        });
        if (arr.length > 0) {
            import("xlsx").then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(arr);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                this.saveAsExcelFile(excelBuffer, "Export_ean_nepronadjenih_cena_");
            });
        }
    }
    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    
  getVouchersAmountsByVoucherID(voucher_id){
    this.vouchers_amounts = [];
    this.configService.httpGet(this.configService.getAPI() + 'vouchers/' + voucher_id + '/vouchers_amounts').subscribe(data => {
      this.vouchers_amounts = data;
    });
  }
}