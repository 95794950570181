import { Injectable, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { MatDialog, MatSidenav } from '@angular/material';
import { Observable } from 'rxjs';

import { catchError, finalize } from 'rxjs/operators';
//import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';


const API = "http://api.appcardian.com/api/gateway/";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  sideNavButton: MatSidenav;
  token = null;
  country_db;
  country_name;
  currency;
  path = '';
  color = "#FBBC04";
  negativeColor = '#184793';
  lightcolor = "lightgray";
  blackColor = "rgb(0, 0, 0)";
  me: any = {};
  translationJSON: any ={};
  loginErrorMessage = "";
  my_gift = false;
  giftForShow: any = {};
  waitingForResponses: Array<any> = [];

  conditions = [ false, false, false, false, false, false, false, false, false, false, false, false, false, false ];
  authorizationData = null;
  header = new HttpHeaders();

  constructor(private https: HttpClient, private router: Router,/*, private changeDetectorRefs: ChangeDetectorRef*/
              public dialog: MatDialog, private cookieService: CookieService) {
    this.token = this.cookieService.get('token');
    this.country_db = this.cookieService.get('country_db');
    this.country_name = this.cookieService.get('country_name');
    this.currency = this.cookieService.get('currency');
    
    let self = this;
    setTimeout(function(){
      if(self.router.url && self.router.url.indexOf('my-gift') == -1) {} //self.router.navigate(['']);
      else self.my_gift = true;
      
      if(this.token){
        this.header = new HttpHeaders({
          'Authorization': 'Bearer ' + this.token
        });
        this.httpGet(this.getAPI() + 'translation').subscribe(data => {
          this.translationJSON = data;
        });
        this.httpGet(API + 'profile').subscribe(data => {
          this.me = data;
          if(this.me.admin != "") this.me.admin = JSON.parse(this.me.admin);
          for(let i = 0; i < this.conditions.length; i++){
            this.conditions[i] = false;
          }
          if(this.me.role & 1){
            for(let i = 0; i < this.conditions.length; i++){
              this.conditions[i] = true;
            }
          }
          if(this.me.role & 2) this.conditions[0] = true;
          if(this.me.role & 4) this.conditions[1] = true; 
          if(this.me.role & 8) this.conditions[2] = true; 
          //this.changeDetectorRefs.detectChanges();
          let where = this.cookieService.get('wherego');
          let queryParams = this.cookieService.get('queryParams');
          if(queryParams != undefined && queryParams != 'undefined' && queryParams != '') this.router.navigate([ where ], { queryParams: JSON.parse(queryParams) });
          else this.router.navigate([ where ]);
        });
      }
      else {
        this.token = null;
      }
    }, 100);

  }

  getAPI(){
    return API;
  }
  

  login(body){
    this.loginErrorMessage = "";
    let h = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let fdata:FormData = new FormData();
    fdata.append("grant_type", 'password');
    fdata.append("client_id", '2');
    fdata.append("client_secret", 'zKE4Ilr1BaCfvCqDlsGAhCgRvxAAB4ikGDaMcYfV');
    fdata.append("country_db", body.country_db);
    fdata.append("grant_type", 'password');
    fdata.append("username", body.username);
    fdata.append("password", body.password);
    this.httpPost(this.getAPI() + 'oauth/token', fdata).subscribe(data => {
      let tmp: any = data;
      this.token = tmp.access_token;
      this.header = new HttpHeaders({
        'Authorization': 'Bearer ' + tmp.access_token
      });
      this.httpGet(this.getAPI() + 'translation').subscribe(data => {
        this.translationJSON = data;
      });
      this.httpGet(API + 'profile').subscribe(data => {
        this.me = data;
        this.me.admin = JSON.parse(this.me.admin);
        console.log(this.me);
        for(let i = 0; i < this.conditions.length; i++){
          this.conditions[i] = false;
        }
        if(this.me.role & 1){
          for(let i = 0; i < this.conditions.length; i++){
            this.conditions[i] = true;
          }
        }
        if(this.me.role & 2) this.conditions[0] = true;
        if(this.me.role & 4) this.conditions[1] = true; 
        if(this.me.role & 8) this.conditions[2] = true; 
        //this.changeDetectorRefs.detectChanges();
      });
      let exp = moment.unix(tmp.expires_in).format("MM/DD/YYYY");
      //console.log(exp);
      let today = new Date();
      this.country_db = body.country_db;
      this.country_name = body.country_name;
      this.currency = body.currency;
      this.cookieService.set('country_db', body.country_db, null, null, null, null, null);
      this.cookieService.set('country_name', body.country_name, null, null, null, null, null);
      this.cookieService.set('currency', body.currency, null, null, null, null, null);
      this.cookieService.set('token', this.token, null, null, null, null, null);
      if (!this.my_gift) this.router.navigate(['dashboard']);
    }, (err) => {
      this.loginErrorMessage = "Neispravni kredencijali.\n Pokušajte ponovo.";
    });
  }
  logout(){
    this.country_name = null;
    this.currency = null;
    this.cookieService.delete('country_name');
    this.cookieService.delete('currency');
    this.cookieService.delete('token');
    this.token = null;
    this.me = {};
    this.router.navigate(['login']);
  }
  sidenavToggle(){
    this.sideNavButton.toggle();
  }
  
  makeid(length: any) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
      }
      return result;
  }
  
  deleteHashFromWaitingArray(hash: any){
    for(let i = 0; i < this.waitingForResponses.length; i++){
      if(this.waitingForResponses[i] == hash) {
        this.waitingForResponses.splice(i, 1);
        i--;
      }
    }
  }

  httpGet(url: string){
    let hash = this.makeid(20);
    this.waitingForResponses.push(hash);
    let self = this;
    setTimeout(function() {
      self.deleteHashFromWaitingArray(hash);
    }, 16000);
    return this.https.get(url, { headers: this.header }).pipe(
      finalize(() => self.deleteHashFromWaitingArray(hash))
   );
  }
  httpPost(url: string, body: any){
    let hash = this.makeid(20);
    this.waitingForResponses.push(hash);
    let self = this;
    setTimeout(function() {
      self.deleteHashFromWaitingArray(hash);
    }, 16000);
    return this.https.post(url, body, { headers: this.header }).pipe(
      finalize(() => self.deleteHashFromWaitingArray(hash))
   );
  }
  httpDelete(url: string){
    let hash = this.makeid(20);
    this.waitingForResponses.push(hash);
    let self = this;
    setTimeout(function() {
      self.deleteHashFromWaitingArray(hash);
    }, 16000);
    return this.https.delete(url, { headers: this.header }).pipe(
      finalize(() => self.deleteHashFromWaitingArray(hash))
   );
  }
}
