<div style="margin:10px">
    <h2 *ngIf="showVendorMatSelect" style="text-align:center">
      <button mat-raised-button [style.background-color]="configServise.color" style="float: left;color:white" (click)="openDialog()"> Import .xlsx</button>
      Gift kartice
      <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" routerLink="/qr-barcode-code"> Dodaj novu</button>
    </h2>
  </div>

  <div class="mat-elevation-z8" style="width:85%;margin:auto">

    <mat-form-field *ngIf="showVendorMatSelect" style="margin:20px;width:150px;vertical-align: top;" >
      <mat-select [(value)]="vouchers_id" placeholder="Vendor" (selectionChange)="applyFilter();getVouchersAmountsByVoucherID(vouchers_id)">
        <mat-option [value]="'all'">{{ configServise.translationJSON.all }}</mat-option>
        <mat-option *ngFor="let item of vouchers" [value]="item.id">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="margin:20px;width:150px;vertical-align: top;" >
      <mat-select [(value)]="vouchers_amounts_value" placeholder="Vrednost" (selectionChange)="applyFilter()">
        <mat-option [value]="'all'">{{ configServise.translationJSON.all }}</mat-option>
        <mat-option *ngFor="let item of vouchers_amounts" [value]="item.value">{{ item.value }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button *ngIf="showVendorMatSelect" mat-raised-button [style.background-color]="configServise.color" style="color:white; margin: 30px; width:120px" (click)="ResetFilter()">Reset filter</button>

    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.id }} </th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let row">{{row.type}}</td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
        <td mat-cell *matCellDef="let row">{{row.code}}</td>
      </ng-container>

      <ng-container matColumnDef="voucher_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vaucer </th>
        <td mat-cell *matCellDef="let row">{{row.voucher_name}}</td>
      </ng-container>

      <ng-container matColumnDef="vouchers_amounts_value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vrednost vaucera </th>
        <td mat-cell *matCellDef="let row">{{row.value}}</td>
      </ng-container>

      <ng-container matColumnDef="is_used">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row">{{row.is_used}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:200px;"></th>
        <td mat-cell *matCellDef="let row" style="width:200px">
            <button *ngIf="row.is_used == 'Dostupan'" mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="gotoEditQR_Barcode(row.id)"> {{ configServise.translationJSON.edit }} </button>
            <button *ngIf="row.is_used == 'Dostupan'"  mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="openDialog1(row.id)"> {{ configServise.translationJSON.delete }} </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator *ngIf="doneLoading"
        [length]="length"
        [(pageIndex)]="pageIndex"
        [(pageSize)]="pageSize"
        [pageSizeOptions]="[15, 25, 50, 100, 250]"
        (page)="onPaginateChange($event)">
    </mat-paginator>
</div>