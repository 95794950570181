
<div style="margin:10px">
  <h2 style="text-align:center">
    {{ configServise.translationJSON.ordered_gifts }}
    <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="openPopup()"><mat-icon>subject</mat-icon>{{ configServise.translationJSON.report }}</button>
  </h2>
</div>
<div class="mat-elevation-z8" style="width:95%;margin:auto">
    <mat-form-field style="margin:20px;width:350px">
      <input matInput [value]="currFilterValue" (keyup)="applyFilter($event.target.value)" [placeholder]="configServise.translationJSON.filter">
    </mat-form-field>

    <mat-form-field style="margin:20px;width:150px">
      <mat-select [(value)]="searchColumn" [placeholder]="configServise.translationJSON.column" (selectionChange)="applyFilter()">
        <mat-option [value]="'all'">{{ configServise.translationJSON.all }}</mat-option>
        <mat-option [value]="'ime_korisnika'">{{ configServise.translationJSON.user }}</mat-option>
        <mat-option [value]="'kompanija'">{{ configServise.translationJSON.customer }}</mat-option>
        <mat-option [value]="'prodavnica'">{{ configServise.translationJSON.business_unit }}</mat-option>
        <mat-option [value]="'ime_artikla'">{{ configServise.translationJSON.gift }}</mat-option>
        <mat-option [value]="'kolicina'">{{ configServise.translationJSON.quantity }}</mat-option>
        <mat-option [value]="'datum'">{{ configServise.translationJSON.date }}</mat-option>
        <mat-option [value]="'poeni'">{{ configServise.translationJSON.points }}</mat-option>
        <mat-option [value]="'datum_slanja'">{{ configServise.translationJSON.send_date }}</mat-option>
      </mat-select>
    </mat-form-field>

    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.user }}</th>
          <td mat-cell *matCellDef="let row">{{row.user_name}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.phone }}</th>
          <td mat-cell *matCellDef="let row">{{row.phone}}</td>
        </ng-container>

        <ng-container matColumnDef="company_name">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.customer }}</th>
          <td mat-cell *matCellDef="let row">{{row.company_name}}</td>
        </ng-container>

        <ng-container matColumnDef="shop_name">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.business_unit }}</th>
          <td mat-cell *matCellDef="let row">{{row.shop_name}}</td>
        </ng-container>

        <ng-container matColumnDef="artical_name">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.gift }}</th>
          <td mat-cell *matCellDef="let row">{{row.artical_name}}</td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.quantity }}</th>
          <td mat-cell *matCellDef="let row">{{row.quantity}}</td>
        </ng-container>

        <ng-container matColumnDef="points">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.points }}</th>
          <td mat-cell *matCellDef="let row">{{row.points}}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.date }}</th>
          <td mat-cell *matCellDef="let row">{{row.created_at}}</td>
        </ng-container>

        <ng-container matColumnDef="sent_date">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.send_date }}</th>
          <td mat-cell *matCellDef="let row">{{row.sent_date}}</td>
        </ng-container>
      
        <ng-container matColumnDef="user_active">
          <th mat-header-cell *matHeaderCellDef>{{ configServise.translationJSON.active_user }}</th>
          <td mat-cell *matCellDef="let row" [style.color]="row.user_active ? 'darkgreen' : 'red'" >{{row.user_active ? configServise.translationJSON.yes : configServise.translationJSON.no }}</td>
        </ng-container>
      
        <ng-container matColumnDef="zuta_tacka">
          <th mat-header-cell *matHeaderCellDef>16.06 - 26.07</th>
          <td mat-cell *matCellDef="let row" > <div *ngIf="row.zuta_tacka" style="background-color: rgb(255, 255, 88);border: 1px solid yellowgreen;width: 20px;height: 20px;"></div> </td>
        </ng-container>
        
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:200px;"></th>
        <td mat-cell *matCellDef="let row" style="width:200px">
            <button mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="gotoDetail(row.id)">{{ configServise.translationJSON.details }}</button>
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color:white" (click)="deleteBoughtArtical(row.id)">{{ configServise.translationJSON.delete }}</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator *ngIf="doneLoading"
        [length]="length"
        [(pageIndex)]="pageIndex"
        [(pageSize)]="pageSize"
        [pageSizeOptions]="[15, 25, 50, 100, 250]"
        (page)="onPaginateChange($event)">
    </mat-paginator>
</div>
<div *ngIf="popup"  style="width:100%;height:100%;background-color:rgba(0, 0, 0, 0.7);z-index:9999;position:fixed;top:0;left:0;">
  <div style="position:fixed;min-height:400px;min-width:500px;background-color:white;top:17%;left:35%">
      <h1 style="font-style:italic;font-weight:bold;text-align:center">{{ configServise.translationJSON.report_ordered_gifts }}</h1>
      <div style="margin:30px 60px;padding: 40px;padding-top:15px;margin-bottom:0">          
        <div style="display:table;margin:auto;margin-bottom:20px;">     
          <mat-radio-group [(ngModel)]="dataIzvestaj.tip" (change)="alert = false" name="radio" style="display:block;margin-bottom:15px;margin-top:15px">
              <mat-radio-button name="radio" [value]="0" style="margin-right:50px">{{ configServise.translationJSON.by_date }}</mat-radio-button>
              <mat-radio-button name="radio" [value]="1">{{ configServise.translationJSON.up_to_now }}</mat-radio-button>
          </mat-radio-group> 
        </div>
        <div style="display:table;margin:auto;;min-height:66px">     
          <p *ngIf="!dataIzvestaj.tip" style="display:inline-block;font-weight:bold;margin-right:30px">{{ configServise.translationJSON.from }}: </p>
          <mat-form-field *ngIf="!dataIzvestaj.tip">
              <input matInput [matDatepicker]="picker" [(ngModel)]="dataIzvestaj.od" placeholder="MM/DD/YYYY" [ngModelOptions]="{timezone: 'UTC'}">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>          
        </div>
        <div style="display:table;margin:auto;min-height:66px">
          <p *ngIf="!dataIzvestaj.tip" style="display:inline-block;font-weight:bold;margin-right:30px;">{{ configServise.translationJSON.to }}: </p>
          <mat-form-field *ngIf="!dataIzvestaj.tip">
              <input matInput [matDatepicker]="picker2" [(ngModel)]="dataIzvestaj.do" placeholder="MM/DD/YYYY">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div style="height: 30px;">
        <p *ngIf="alert" style="font-weight:bold;margin:0px;text-align: center;" [style.color]="configServise.color">{{ alertText }}</p>
      </div>
      <div style="margin-bottom:30px">
        <div style="display:inline-block;width:50%">
          <div style="display:table;margin:auto;">
            <img *ngIf="waiting" src="assets/loading-png-gif-original.gif" style="width:30px" />
            <button *ngIf="!waiting" mat-raised-button [style.background-color]="configServise.color" style="color:white" (click)="generisiExcel()"><mat-icon>check</mat-icon>{{ configServise.translationJSON.generate }}</button>
          </div>
        </div>
        <div style="display:inline-block;width:50%">
          <div style="display:table;margin:auto;">
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color:white" (click)="closePopup()"><mat-icon>clear</mat-icon>{{ configServise.translationJSON.back }}</button>
          </div>
        </div>
      </div>
  </div>
</div>