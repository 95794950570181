<h2 style="text-align:center">
    {{ configServise.translationJSON.push_notification_logo_overview }}
</h2>
<div style="height:15px"></div>
<div style="margin-left:40px">
    <mat-checkbox [disabled]="true" [(ngModel)]="data.vidljivost.svi" color="primary"> {{ configServise.translationJSON.all }} </mat-checkbox>
    <div>
        <div *ngFor="let item of data.vidljivost.child">
            <mat-icon *ngIf="!item.expanded" (click)="toggleExpanded(item)" class="arrowToggle">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="item.expanded" (click)="toggleExpanded(item)" class="arrowToggle">keyboard_arrow_down</mat-icon>
            <mat-checkbox [disabled]="true" [(ngModel)]="item.checked" color="primary" style="vertical-align: top;margin-top:2px;">{{ item.name }} ({{ item.cntselected }})</mat-checkbox>
            
            <div *ngIf="item.expanded" style="margin-left:50px">
                <div *ngFor="let item2 of item.child">
                    <div style="width:24px;display: inline-block;">
                        <mat-icon *ngIf="!item2.expanded && item2.users.length > 0" (click)="toggleExpanded(item2)" class="arrowToggle">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="item2.expanded" (click)="toggleExpanded(item2)" class="arrowToggle">keyboard_arrow_down</mat-icon>
                    </div>
                    <mat-checkbox [disabled]="true" [(ngModel)]="item2.checked" color="primary" style="vertical-align: top;margin-top:2px;" [disabled]="item2.users.length == 0">{{ item2.name }} {{ item2.users.length == 0?' (nema korisnike)':'' }}</mat-checkbox>
                    
                    <div *ngIf="item2.expanded" style="margin-left:50px">
                        <div *ngFor="let item3 of item2.users">
                            <mat-checkbox [disabled]="true" [(ngModel)]="item3.checked" color="primary" style="vertical-align: top;margin-top:2px;" [disabled]="item3.devices.length == 0">{{ item3.name }} {{ item3.devices.length == 0?' - nedostupan korisnik':'' }}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div style="height:40px"></div>
<mat-form-field style="width:50%;margin:30px;display:block">
    <input [disabled]="true" matInput [(ngModel)]="data.naslov" maxlength="150" placeholder="Naslov" />
</mat-form-field>
<mat-form-field style="width:50%;margin:30px;display:block">
    <textarea [disabled]="true" matInput [(ngModel)]="data.text" maxlength="292" placeholder="Tekst" style="height:100px"></textarea>
</mat-form-field>