<h2 style="text-align:center">
    {{ configServise.translationJSON.sms_notifications }}
</h2>
<div style="height:15px"></div>
<mat-form-field style="width:50%;margin:30px;display:block">
    <textarea matInput [(ngModel)]="data.text"  maxlength="150" [placeholder]="configServise.translationJSON.text" style="height:150px"></textarea>
</mat-form-field>

<button mat-raised-button [style.background-color]="configServise.color" style="color:white;margin:30px" (click)="openDialog1()"> {{ configServise.translationJSON.send }} </button>
<div *ngIf="popup"  style="width:100%;height:100%;background-color:rgba(0, 0, 0, 0.7);z-index:9999;position:fixed;top:0;left:0;">
    <div style="position:fixed;width:30%;height:50%;background-color:white;top:17%;left:35%">
        <h1 style="font-style:italic;font-weight:bold;text-align:center"> {{ configServise.translationJSON.sms_to_all_users }} </h1>
        <h2 style="text-align:center"> {{ configServise.translationJSON.sent }}: {{ cnt }} / {{ users.length }}</h2>
        <mat-progress-spinner
            style="margin-left:calc(50% - 50px)"
            class="example-margin"
            [color]="configServise.color"
            mode="determinate"
            [value]="(cnt / users.length) * 100">
        </mat-progress-spinner>
        <h2 style="text-align:center">{{ (cnt / users.length) * 100 | number:'1.0-0' }} %</h2>
        <p style="text-align:center;margin-top:10px"> {{ configServise.translationJSON.please_wait }},<br/>{{ configServise.translationJSON.and_do_not_close_this_window }}.</p>
    </div>
</div>