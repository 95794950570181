import { DatePipe,Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-add-new-qr-barcode-code',
  templateUrl: './add-new-qr-barcode-code.component.html',
  styleUrls: ['./add-new-qr-barcode-code.component.css']
})
export class AddNewQrBarcodeCodeComponent implements OnInit {
  data: any = { };
  update:any = null;
  file: any;
  file2: any;
  validForm = true;
  configServise;
  vouchers:any = [];
  vouchers_amounts: any = [];

  constructor(private _location: Location,private https: HttpClient, configServise: ConfigService, private router: Router,public dialog:MatDialog , private route: ActivatedRoute, public datepipe: DatePipe) { 
    this.configServise = configServise;
  }

  ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    this.configServise.httpGet(this.configServise.getAPI() + 'all_vouchers').subscribe(data => {
      this.vouchers = data;      
      if(this.update){
        this.configServise.httpGet(this.configServise.getAPI() + 'qr_barcodes/' + this.update).subscribe(data => {
          this.data = data;
          this.getVouchersAmountsByVoucherID(this.data.vouchers_id)
        });
      }
    });
  }

  addQR_Barcode(){
    if(this.validateForm()){
    let body = { type: this.data.type, code: this.data.code , vouchers_amounts_id: this.data.vouchers_amounts_id};
    this.configServise.httpPost(this.configServise.getAPI() + 'insert_or_update_qr_barcode', body).subscribe(data => {
      let tmp: any = data;
      if(tmp.result == true){
        this.router.navigate(['qr-barcode-codes']);
      }
    });
  }
  }

  openAlert(): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: "Popunite sva polja." }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result && result.agree) {

      }
    });
  }

  validateForm() {
    if (this.data.type == "" || this.data.type == null || this.data.type == undefined) {
      this.openAlert();
      return false
    }
    if (this.data.code == "" || this.data.code == null || this.data.code == undefined) {
      this.openAlert();
      return false
    }
    if (this.data.vouchers_id == "" || this.data.vouchers_id == null || this.data.vouchers_id == undefined) {
      this.openAlert();
      return false
    }
    if (this.data.vouchers_amounts_id == "" || this.data.vouchers_amounts_id == null || this.data.vouchers_amounts_id == undefined) {
      this.openAlert();
      return false
    }
    return true
  }

  updateQR_Barcode(){
    let body = { id: this.update, type: this.data.type, code: this.data.code, vouchers_amounts_id: this.data.vouchers_amounts_id};
    this.configServise.httpPost(this.configServise.getAPI() + 'insert_or_update_qr_barcode', body).subscribe(data => {
      let tmp: any = data;
      if(tmp.result == true){
        this.router.navigate(['qr-barcode-codes']);
      }
    });
  }

  checkLength(event){
    if(event.keyCode == 69 || event.srcElement.value.length > 1 && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))){
      event.preventDefault();
    };
  }
  onVoucherChange(ob) {
    let selectedVocuher = ob.value;
    this.data.vouchers_amounts_id = null;
    this.vouchers_amounts = [];
    this.getVouchersAmountsByVoucherID(selectedVocuher);
  }

  getVouchersAmountsByVoucherID(voucher_id){
    this.configServise.httpGet(this.configServise.getAPI() + 'vouchers/' + voucher_id + '/vouchers_amounts').subscribe(data => {
      this.vouchers_amounts = data;
    });
  }

  generateQR_Barcode(code){
    this.configServise.httpGet(this.configServise.getAPI() + 'generate_qr_barcode').subscribe(data => {
      this.vouchers_amounts = data;
    });
  }

  goback(){
    this._location.back();
  }


}
