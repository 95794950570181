import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import {MatCardModule, MatRadioModule, MatMenuModule, MatDatepicker, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatCheckbox, MatCheckboxModule, MatProgressBarModule, MatFormFieldModule, MatRippleModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UserComponent } from './user/user.component';
import { AddNewUserComponent } from './user/add-new-user/add-new-user.component';
import { DatePipe } from '@angular/common';
import { BoughtArticalComponent } from './bought-artical/bought-artical.component';
import { AddNewBoughtArticalComponent } from './bought-artical/add-new-bought-artical/add-new-bought-artical.component';
import { LoginComponent } from './login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { AddNewCategoryComponent } from './category/add-new-category/add-new-category.component';
import { CategoryComponent } from './category/category.component';

import { NotificationsComponent } from './notifications/notifications.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { AddNewVoucherComponent } from './vouchers/add-new-voucher/add-new-voucher.component';
import { AdministrationComponent } from './administracija/administration.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { TermsComponent } from './terms-conditions/terms.component';
import { LogsComponent } from './logs/logs.component';
import { SmsLogsComponent } from './logs/sms-logs/sms-logs.component';
import { PushLogsComponent } from './logs/push-logs/push-logs.component';
import { AutoPushLogsComponent } from './logs/auto-push-logs/auto-push-logs.component';
import { PushLogSingleComponent } from './logs/push-log-single/push-log-single.component';
import { QRBarcodeCodesComponent } from './qr-barcode-codes/qr-barcode-codes.component';
import { AddNewQrBarcodeCodeComponent } from './qr-barcode-codes/add-new-qr-barcode-code/add-new-qr-barcode-code.component';
import { MessagesComponent } from './messages/messages.component';
import { AnswerMessageComponent } from './messages/answer-message/answer-message.component';
import { GiftsComponent } from './gifts/gifts.component';
import { ReviewGiftComponent } from './gifts/review-gift/review-gift.component';
import { VouchersAmountsComponent } from './vouchers-amounts/vouchers-amounts.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ImportDialog } from './qr-barcode-codes/import-dialog/import-dialog.component';

import { QuillModule } from 'ngx-quill'
import { MatMomentDateModule } from '@angular/material-moment-adapter';




@NgModule({
  declarations: [
    AppComponent,
    AlertDialogComponent,
    TermsComponent,
    MainNavComponent,
    AdministrationComponent,
    ConfirmDialogComponent,
    UserComponent,
    AddNewUserComponent,
    VouchersComponent,
    AddNewVoucherComponent,
    BoughtArticalComponent,
    AddNewBoughtArticalComponent,
    LoginComponent,
    CategoryComponent,
    AddNewCategoryComponent,
    NotificationsComponent,
    PushNotificationComponent,
    FileUploaderComponent,
    LogsComponent,
    SmsLogsComponent,
    PushLogsComponent,
    AutoPushLogsComponent,
    PushLogSingleComponent,
    QRBarcodeCodesComponent,
    AddNewQrBarcodeCodeComponent,
    MessagesComponent,
    AnswerMessageComponent,
    GiftsComponent,
    ReviewGiftComponent,
    VouchersAmountsComponent,
    DashboardComponent,
    LandingPageComponent,
    ImportDialog
  ],
  imports: [
    MatMomentDateModule,
    MatMenuModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ChartsModule,
    MatProgressBarModule,
    QuillModule.forRoot({
      customOptions: [{
        import: 'formats/font',
        whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace']
      }]
    })
  ],
  entryComponents: [ ConfirmDialogComponent, AlertDialogComponent, ImportDialog ],
  providers: [ MatDatepickerModule, DatePipe, CookieService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
