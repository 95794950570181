
<div style="margin:10px">
  <h2 style="text-align:center">
    {{ configServise.translationJSON.terms_and_conditions }}
    <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="edit_mode = true">{{ configServise.translationJSON.edit }}</button>
  </h2>
</div>
<div class="mat-elevation-z8" style="width:80%;margin:auto">  
  <mat-card>
    <div *ngIf="edit_mode">
      <mat-form-field style="width:100%;display:block;max-width: 1150px;">
          <input matInput [(ngModel)]="data.title" [placeholder]="configServise.translationJSON.title">
      </mat-form-field>

      <div style="padding: 30px 0">
        <quill-editor [(ngModel)]="data.text"></quill-editor>
      </div>
  
      <button mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="saveTerms()">{{ configServise.translationJSON.save }}</button>
      <button mat-raised-button [style.background-color]="configServise.negativeColor" (click)="edit_mode = false" style="margin-left:30px;color:white">{{ configServise.translationJSON.cancel }}</button>
    </div>
    <div *ngIf="!edit_mode">
      <h3>{{ data.title }}</h3>
      <div [innerHtml]="data.text" ></div>
    </div>
  </mat-card>
</div>