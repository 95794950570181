import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-vouchers-amounts',
  templateUrl: './vouchers-amounts.component.html',
  styleUrls: ['./vouchers-amounts.component.css']
})
export class VouchersAmountsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  dataSource: any = [];
  configServise;
  update: any = null;
  new_voucher_value = '';
  add_new_voucher: any = false;

  displayedColumns = ['id', 'value', 'qr_barcode_count', 'action'];

  constructor(private https: HttpClient, configServise: ConfigService, public dialog:MatDialog,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router, private route: ActivatedRoute) {
    this.configServise =configServise  
  }

  ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    this.dataSource = new MatTableDataSource();
    this.configServise.httpGet(this.configServise.getAPI() + 'vouchers/' + this.update + '/vouchers_amounts').subscribe(data => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = <Array<any>> data;
      this.table.dataSource = this.dataSource;
    });
  }
  deleteVouchersAmounts(id){
      this.configServise.httpDelete(this.configServise.getAPI() + 'delete_amount/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.dataSource.data = <Array<any>> data;
          this.changeDetectorRefs.detectChanges();
        }
        else {
          alert(tmp.text);
        }
      });
  }

  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: "Sigurni ste da zelite da obriste vrednost vaučera ?"
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if(result && result.agree){
        this.deleteVouchersAmounts(id);
      }
    });
  }
  add(){
    this.add_new_voucher = true;
  }
  save(){
    this.configServise.httpPost(this.configServise.getAPI() + 'insert_amount', { vouchers_id: this.update, value: this.new_voucher_value }).subscribe(data => {
      let tmp:any = data;
      if(tmp.result != false){
        this.new_voucher_value = null;
        this.add_new_voucher = false;
        this.ngOnInit();
      }
      else {
        alert(tmp.text);
      }
    });
  }
}
