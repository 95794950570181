<div style="margin:10px">
    <h2 style="text-align:center">
        <span *ngIf="update"> Pregled poruka </span>
        <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" routerLink="/messages"> Sve poruke </button>
    </h2>
</div>
<mat-card>

    <div>
        <p style="font-weight:bold;margin-right:20px">Poruka: </p>
        <p>{{ data.message }}</p>
    </div>

    <mat-form-field style="width:50%;display:block">
        <input matInput [(ngModel)]="data.answer" placeholder="Ogdovor">
    </mat-form-field>

    <div>
        <p style="font-weight:bold;margin-right:20px">Korisnik: </p>
        <p>{{ data.user_name }}</p>
    </div>


    <div>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="answerMessage()"> Odgovori </button>
        <button *ngIf="update" mat-raised-button [style.background-color]="configServise.negativeColor"  routerLink="/messages" style="margin-left:30px;color:white"> {{ configServise.translationJSON.cancel }} </button>
    </div>
</mat-card>

