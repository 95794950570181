import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ResponseContentType } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {saveAs as importedSaveAs} from "file-saver";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
})
export class UserComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  allData:any = [];
  dataSource: any = [];
  searchColumn = 'all';
  currFilterValue = "";
  configServise;  
  length = 0;
  alert = false;
  alertText = "* Polja su obavezna";
  popup = false;
  dataIzvestaj: any = { tip: 0 };
  waiting = false;
  pageIndex = "0";
  pageSize = "15";
  doneLoading = true;
  wait = 0;
  orderData: any = {
    column: 'answered_at',
    order: 'ASC'
  }

  constructor(private https: HttpClient, configServise: ConfigService,
              public datepipe: DatePipe, public route: ActivatedRoute,
              private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
                this.configServise =configServise;
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'email', 'phone', 'money_available', 'is_admin', 'active', 'answered_on_last_message', 'created_at', 'action'];
  columns = [{
    view: 'Ime',
    value: 'name'
  }, {
    view: 'Email',
    value: 'email'
  }, {
    view: 'Telefon',
    value: 'phone'
  }, {
    view: 'Novcanik',
    value: 'money_available'
  }, {
    view: 'Neodgovorene poruke',
    value: 'answered_at'
  }, {
    view: 'Datum registracije',
    value: 'created_at'
  } ];


  ngOnInit() {
    let pageIndex = this.route.snapshot.queryParamMap.get("pageIndex");
    if(pageIndex) this.pageIndex = pageIndex;
    let pageSize = this.route.snapshot.queryParamMap.get("pageSize");
    if(pageSize) this.pageSize = pageSize;
    let currFilterValue = this.route.snapshot.queryParamMap.get("currFilterValue");
    if(currFilterValue) this.currFilterValue = currFilterValue;    
    let orderColumn = this.route.snapshot.queryParamMap.get("orderColumn");
    if(orderColumn) this.orderData.column = orderColumn;    
    let orderSort = this.route.snapshot.queryParamMap.get("orderSort");
    if(orderSort) this.orderData.order = orderSort;    
    this.dataSource = new MatTableDataSource();
  }
  ngAfterViewInit() {
    this.doneLoading = false;    
    this.dataSource = new MatTableDataSource();
    this.configServise.httpPost(this.configServise.getAPI() + 'users/' + this.pageIndex + '/' + this.pageSize + (this.currFilterValue ? '/' + this.currFilterValue : ''), this.orderData).subscribe(data => {
      this.length = (<any>data).count;
      this.allData = <Array<any>> (<any>data).data;
      this.doneLoading = true;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.allData;
      this.table.dataSource = this.dataSource;
      let self = this;
    });
  }

  deleteUser(phone){
    if(confirm(this.configServise.translationJSON.are_you_sure_you_want_to_delete_the_user + "?")){
      this.configServise.httpDelete(this.configServise.getAPI() + 'users/' + phone).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.applyFilter();
        }
        else {
          alert(this.configServise.translationJSON.failed_delete);
        }
      });
    }
  }
  onPaginateChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageSize: e.pageSize,
        pageIndex: e.pageIndex,
        currFilterValue: this.currFilterValue,
        orderColumn: this.orderData.column,
        orderSort: this.orderData.order
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    //this.ngOnInit();
    this.ngAfterViewInit();
  }
  gotoEditUser(id){
    /*let url = this.router.createUrlTree(['/user'])
    window.open(url.toString() + '?id=' + id, '_blank')*/
    this.router.navigate(['user'], { queryParams: { id: id }});
  }
  unblock(phone){
    this.configServise.httpPost(this.configServise.getAPI() + 'unblockuser', { phone: phone }).subscribe(data => {
      let tmp:any = data;
      if(tmp.result != false){
        this.allData = <Array<any>> data;
        this.dataSource.data = <Array<any>> data;
        this.changeDetectorRefs.detectChanges();
      }
      else {
        alert(this.configServise.translationJSON.failed_to_unlock);
      }
    });
  }
  block(phone){
    this.configServise.httpPost(this.configServise.getAPI() + 'blockuser', { phone: phone }).subscribe(data => {
      let tmp:any = data;
      if(tmp.result != false){
        this.allData = <Array<any>> data;
        this.dataSource.data = <Array<any>> data;
        this.changeDetectorRefs.detectChanges();
      }
      else {
        alert(this.configServise.translationJSON.failed_to_block);
      }
    });
  }
  
  applyFilter() {
    /*if(filterValue == "") {
      this.currFilterValue = null;
    }
    else {
      if(filterValue) this.currFilterValue = filterValue;
      else filterValue = this.currFilterValue;
    }*/
    this.wait++
    let self = this;
    setTimeout(() => { 
      self.wait--
      if(self.wait == 0) {   
        self.pageIndex = "0";  
        self.router.navigate([], {
          relativeTo: self.route,
          queryParams: {
            pageSize: self.pageSize,
            pageIndex: self.pageIndex,
            currFilterValue: self.currFilterValue,
            orderColumn: this.orderData.column,
            orderSort: this.orderData.order
          },
          queryParamsHandling: 'merge',
          // preserve the existing query params in the route
          skipLocationChange: false
          // do not trigger navigation
        });   
        //self.ngOnInit();
        self.ngAfterViewInit();
      }
    }, 1000);
  }
  generisiExcel(){
    if(this.dataIzvestaj.tip || !this.dataIzvestaj.tip && this.dataIzvestaj.od && this.dataIzvestaj.do){
      if(this.dataIzvestaj.do <= this.dataIzvestaj.od){
        this.alert = true;
        this.alertText = this.configServise.translationJSON.incorrect_entry;
      } else {
        this.alert = false;
        this.dataIzvestaj.od = this.datepipe.transform(this.dataIzvestaj.od, 'yyyy-MM-dd');
        this.dataIzvestaj.do = this.datepipe.transform(this.dataIzvestaj.do, 'yyyy-MM-dd');
        this.waiting = true;
        this.dataIzvestaj.filename = 'users_generated_' + Date.now() + '_by_uid' + this.configServise.me.id + '.xlsx';
        // 'generateuserexcel'
        this.configServise.httpPost(this.configServise.getAPI() + 'generateuserexcel', this.dataIzvestaj).subscribe(data => {
          let tmp: any;
          tmp = (<any>data).result;
            let self = this;
            self.closePopup();
            self.waiting = false;
            let filename = tmp.split("/")[tmp.split("/").length - 1];
            self.https.get(tmp, { responseType: 'blob' }).subscribe(blob => {
              importedSaveAs(blob, filename);
            });
        }, err => {
              let self = this;
              self.dataIzvestaj;
              let f = self.dataIzvestaj.filename;
              self.donwload(f);
        });
      }
    }
    else {
      this.alertText = this.configServise.translationJSON.fields_are_required;
      this.alert = true;
    }
  }
  donwload(f){  
    let self = this;  
    /*setTimeout(function(){
      self.configServise.httpGet('http://api.digitalspark-gorenje.com' + '/mediaexcel/' + f, { responseType: 'blob' }).subscribe(blob => {
        self.closePopup();
        self.waiting = false;
        importedSaveAs(blob, f);
      }, err => {
        self.donwload(f);
      });
    }, 10000);*/
  }

  openPopup(){
    this.waiting = false;
    this.alert = false;
    this.dataIzvestaj = { tip: 0 };
    this.configServise.sidenavToggle();
    this.popup = true;
  }
  closePopup(){
    this.waiting = false;
    this.alert = false;
    this.dataIzvestaj = { tip: 0 };
    this.configServise.sidenavToggle();
    this.popup = false;
  }
}
