import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user/user.component';
import { AddNewUserComponent } from './user/add-new-user/add-new-user.component';
import { BoughtArticalComponent } from './bought-artical/bought-artical.component';
import { AddNewBoughtArticalComponent } from './bought-artical/add-new-bought-artical/add-new-bought-artical.component';
import { LoginComponent } from './login/login.component';
import { CanActivateService } from './services/canactivate.service';
import { CategoryComponent } from './category/category.component';
import { AddNewCategoryComponent } from './category/add-new-category/add-new-category.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { AddNewVoucherComponent } from './vouchers/add-new-voucher/add-new-voucher.component';
import { AdministrationComponent } from './administracija/administration.component';
import { LogsComponent } from './logs/logs.component';
import { PushLogSingleComponent } from './logs/push-log-single/push-log-single.component';
import { TermsComponent } from './terms-conditions/terms.component';
import { QRBarcodeCodesComponent } from './qr-barcode-codes/qr-barcode-codes.component';
import { AddNewQrBarcodeCodeComponent } from './qr-barcode-codes/add-new-qr-barcode-code/add-new-qr-barcode-code.component';
import { MessagesComponent } from './messages/messages.component';
import { AnswerMessageComponent } from './messages/answer-message/answer-message.component';
import { GiftsComponent } from './gifts/gifts.component';
import { ReviewGiftComponent } from './gifts/review-gift/review-gift.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [{
  path: '',
  component: LoginComponent
},{
  path: 'login',
  component: LoginComponent
},{
  path: 'dashboard',
  component: DashboardComponent
},{
  path: 'administration',
  component: AdministrationComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'users',
  component: UserComponent, 
  canActivate: [ CanActivateService ]
},{
  path: 'user',
  component: AddNewUserComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'bougtharticals',
  component: BoughtArticalComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'bougthartical',
  component: AddNewBoughtArticalComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'categories',
  component: CategoryComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'category',
  component: AddNewCategoryComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'notifications',
  component: NotificationsComponent,
  canActivate: [ CanActivateService ]
}, {
  path: 'terms_and_conditions',
  component: TermsComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'push-notifications',
  component: PushNotificationComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'vouchers',
  component: VouchersComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'voucher',
  component: AddNewVoucherComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'logs',
  component: LogsComponent,
  canActivate: [ CanActivateService ]
}, {
  path: 'pushlogsingle',
  component: PushLogSingleComponent,
  canActivate: [ CanActivateService ]
}, {
  path: 'qr-barcode-codes',
  component: QRBarcodeCodesComponent,
  canActivate: [ CanActivateService ]
}, {
  path: 'qr-barcode-code',
  component: AddNewQrBarcodeCodeComponent,
  canActivate: [ CanActivateService ]
}, {
  path: 'messages',
  component: MessagesComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'message',
  component: AnswerMessageComponent,
  canActivate: [ CanActivateService ]
},{
  path: 'gifts',
  component: GiftsComponent,
  canActivate: [CanActivateService]
},{
  path: 'gift',
  component: ReviewGiftComponent,
  canActivate: [CanActivateService]
},{
  path: 'users',
  component: UserComponent,
  canActivate: [CanActivateService]
},{
  path: 'user',
  component: AddNewUserComponent,
  canActivate: [CanActivateService]
},{
  path: 'my-gift/:id',
  component: LandingPageComponent
}]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
