import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';


@Component({
  selector: 'app-push-log-single',
  templateUrl: './push-log-single.component.html',
  styleUrls: ['./push-log-single.component.css']
})
export class PushLogSingleComponent implements OnInit {
  configServise;
  id;
  data: any = { vidljivost: {}};


  constructor(private https: HttpClient, configServise: ConfigService, private route: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
      this.configServise =configServise;
  }

  ngOnInit() {  
    this.id = this.route.snapshot.queryParamMap.get("id");   
    this.configServise.httpGet(this.configServise.getAPI() + 'logs/PUSH/' + this.id).subscribe(data => {
      this.data.naslov = JSON.parse((<any>data).message).naslov;
      this.data.text = JSON.parse((<any>data).message).text;
      if((<any>data).note != "") {
        this.data.vidljivost = JSON.parse((<any>data).note);
      }
    });
  }
  
  toggleExpanded(item){
    item.expanded = ~item.expanded;
  }

}
