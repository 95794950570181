import { Injectable, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class CanActivateService implements CanActivate {
  constructor(private https: HttpClient, private router: Router, private cookieService: CookieService, private configService: ConfigService) {
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if(this.configService.me && this.configService.me.admin){
      this.cookieService.delete('wherego');
      this.cookieService.delete('queryParams');
      if(this.configService.me.admin[route.routeConfig.path]) return true;
    }
    this.cookieService.set('wherego', route.routeConfig.path, null, null, null, null, null);
    this.cookieService.set('queryParams', JSON.stringify(route.queryParams), null, null, null, null, null);
    return false;
  }

}
