import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-new-category',
  templateUrl: './add-new-category.component.html',
  styleUrls: ['./add-new-category.component.css']
})
export class AddNewCategoryComponent implements OnInit {
  data: any = { };
  update:any = null;
  file: any;
  file2: any;
  validForm = true;
  configServise;
  constructor(private https: HttpClient, configServise: ConfigService, private router: Router,public dialog:MatDialog , private route: ActivatedRoute, public datepipe: DatePipe) {
    this.configServise =configServise;
  }

  ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    if(this.update){
      this.configServise.httpGet(this.configServise.getAPI() + 'categories/' + this.update).subscribe(data => {
        this.data = data;
      });
    }
  }

  addCategory(){
    if(this.validateForm()){
    let body = { name: this.data.name, icon: this.data.icon  };
    this.configServise.httpPost(this.configServise.getAPI() + 'insert_or_update_categories', body).subscribe(data => {
      let tmp: any = data;
      if(tmp.result == true){
        this.router.navigate(['categories']);
      }
    });
  }
  }

  openAlert(): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: "Unesite naziv kategorije!" }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result && result.agree) {

      }
    });
  }

  validateForm() {
    if (this.data.name == "" || this.data.name == null || this.data.name == undefined) {
      this.openAlert();
      return false
    }
    return true
  }

  updateRecord(){
    let body = { id: this.update, name: this.data.name, icon: this.data.icon };
    this.configServise.httpPost(this.configServise.getAPI() + 'insert_or_update_categories', body).subscribe(data => {
      let tmp: any = data;
      if(tmp.result == true){
        this.router.navigate(['categories']);
      }
    });
  }
  checkLength(event){
    if(event.keyCode == 69 || event.srcElement.value.length > 1 && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))){
      event.preventDefault();
    };
  }

}
