import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';


@Component({
  selector: 'app-push-logs',
  templateUrl: './push-logs.component.html',
  styleUrls: ['./push-logs.component.css']
})
export class PushLogsComponent implements OnInit {
  configServise;
  
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('pushtable', {static: false}) table: MatTable<any>;
  dataSource: any = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'event', 'naslov', 'tekst', 'created_at', 'action'];


  constructor(private https: HttpClient, configServise: ConfigService,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
      this.configServise =configServise;
  }

  ngOnInit() {  
    this.dataSource = new MatTableDataSource();  
    this.configServise.httpGet(this.configServise.getAPI() + 'logs/PUSH').subscribe(data => {
      (<any>data).forEach(element => {
        element.message = JSON.parse(element.message);
      });
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = <Array<any>> data;
      this.table.dataSource = this.dataSource;
    });
  }
  
  gotoSinglePushLog(id){
    this.router.navigate(['pushlogsingle'], { queryParams: { id: id }});
  }

}
