import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements AfterViewInit,OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  dataSource: any = [];
  configServise;
  
  constructor(private https: HttpClient, configServise: ConfigService, public dialog:MatDialog,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router) {    
    this.configServise = configServise;
  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
  }
  
  displayedColumns = ['id', 'message', 'answer', 'user', 'action'];

  ngAfterViewInit(): void {
    this.configServise.httpGet(this.configServise.getAPI() + 'messages').subscribe(data => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = <Array<any>> data;
      this.table.dataSource = this.dataSource;
    });
  }

  deleteMessage(id){
      this.configServise.httpDelete(this.configServise.getAPI() + 'delete_message/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.dataSource.data = <Array<any>> data;
          this.changeDetectorRefs.detectChanges();
        }
        else {
          alert(tmp.text);
        }
      });
  }

  gotoEditMessage(id){
    this.router.navigate(['message'], { queryParams: { id: id }});
  }

  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: "Sigurni ste da zelite da obrisete poruku?"
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if(result && result.agree){
        this.deleteMessage(id);
      }
    });
  }

}
