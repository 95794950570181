import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, Location } from '@angular/common'

import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-add-new-bought-artical',
  templateUrl: './add-new-bought-artical.component.html',
  styleUrls: ['./add-new-bought-artical.component.css']
})
export class AddNewBoughtArticalComponent implements OnInit {
  data: any = { };
  update:any = null;
  file: any;
  validForm = true;
  configServise;
  options: any = [];
  myControl = new FormControl();
  vouchername = "";
  filteredOptions: Observable<any[]>;
  locked = false;
  constructor(private https: HttpClient, configServise: ConfigService, private router: Router, private route: ActivatedRoute, public datepipe: DatePipe
    , private _location: Location) {
    this.configServise = configServise;
  }

  ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    if(this.update){
      this.configServise.httpGet(this.configServise.getAPI() + 'vouchers').subscribe(data => {
        this.options = data;
        
        this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map((value: string) => this._filter(value))
        );
        this.configServise.httpGet(this.configServise.getAPI() + 'bougtharticals/' + this.update).subscribe(data => {
          this.data = data;
          if(this.data.vouchers_id){
            this.locked = true;
            this.options.forEach(element => {
              if(this.data.vouchers_id == element.id){
                this.data.vouchers_id = element.id;
                this.vouchername = element.name_formatted;
              }
            });
          }
          else {
            this.clearVoucherId();
            if(this.data.zuta_tacka == 1) this.locked = true;
          }
        });
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public selectVoucher(event){
    this.data.vouchers_id = event.option.value;
    this.options.forEach(element => {
      if(element.id == event.option.value){
        this.vouchername = element.name_formatted;
      }
    });
  }
  public clearVoucherId(){
    this.data.vouchers_id = null;
    this.vouchername = "";
  }
  

  validateForm(){
  }
  save(){
    if(!this.data.icon_voucher || !this.data.vouchers_id || !this.data.sent_date || !this.data.serial_number){
      alert(this.configServise.translationJSON.fill_in_all_the_fields + "!");
      return
    } 
    if(!(typeof this.data.sent_date === 'string' || this.data.sent_date instanceof String)) this.data.sent_date.setHours(12);
    this.configServise.httpPost(this.configServise.getAPI() + 'bougtharticals/sendgift/' + this.update, this.data).subscribe(data => {
      this.data = data;
      if(this.data.vouchers_id){
        this.locked = true;
        this.options.forEach(element => {
          if(this.data.vouchers_id == element.id){
            this.data.vouchers_id = element.id;
            this.vouchername = element.name;
          }
        });
      }
      else {
        this.clearVoucherId();
      }
      this.goback();
    });
  }
  editSeralNumber(){
    if(confirm('Da li ste sigurni da želite da izmenite serijski broj ?')) {
      this.configServise.httpPost(this.configServise.getAPI() + 'bougtharticals/editserialnumber/' + this.update, this.data).subscribe(data => {

      });
    }
  }
  goback(){
    this._location.back();
  }

}
