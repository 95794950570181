<div style="width:1000px;display:table;margin:auto;padding-top:30px" class="width95Mobile padding10pxMobile">
    <div style="display: inline-block;width:65%;vertical-align: top" class="width100Mobile">
        <p style="font-size:25px"><b [style.color]="configService.negativeColor">{{ configService.giftForShow.name }}</b> ti šalje poklon preko Cardian app</p>
        <div style="padding:50px;border-radius:15px;border: 1px solid lightgray;width:75%" class="widthAutoMobile padding10pxMobile">
            <img [src]="configService.giftForShow.icon_code" [style.width]="configService.giftForShow.type == 'QRcode' ? '40%' : '100%'" style="display: table;margin:auto;"  class="width60Mobile" />
        </div>
        <div style="height: 30px;"></div>
        <div>
            <img [src]="configService.giftForShow.icon" style="width: 200px;padding:10px" class="width60Mobile diplayTableMarginAutoMobile" />
            <div style="display: inline-block;vertical-align: top;" class="diplayTableMarginAutoMobile">
                <p style="font-size:22pt;font-weight:bold;display:inline-block;vertical-align: top;padding-left:50px;margin:0" [style.color]="configService.negativeColor" class="padding0Mobile">{{ configService.giftForShow.value }}</p>
                <p style="font-size:13pt;vertical-align:top;display:inline-block;padding-top:12px;font-weight:bold;padding-left:5px;margin-right: 70px;margin:0" [style.color]="configService.negativeColor">RSD</p>
            </div>
        </div>
        <div style="height: 30px"></div>
        <p style="margin: 7px 0;"><b>Serijski broj vaučera:</b> {{ configService.giftForShow.code }}</p>
        <p style="margin: 7px 0;"><b>Datum isporuke:</b> {{ configService.giftForShow.date }}</p>
        <p style="margin: 7px 0;"><b>Važi do:</b> {{ configService.giftForShow.valid_to }}</p>
        <p style="margin: 7px 0;"><b>Informacija 1:</b> /</p>
        <p style="margin: 7px 0;"><b>Informacija 2:</b> /</p>
        <div style="margin-top: 30px;padding-right: 25px;">
            {{ configService.giftForShow.description }}
        </div>
    </div>
    <div style="display: inline-block;width:35%;vertical-align: top;padding-top: 25px;" class="width95Mobile">
        <img src="assets/Cardian.png" style="width:100%" />
        
      <img src="assets/cardian_logo.png" style="height:54px;display: block;margin:auto;margin-top:10px" />
      <div style="padding-left: 55px" class="padding0Mobile">
        <p style="font-size:14pt;font-weight: bold;" class="textAlignCenterMobile">PREUZMI APLIKACIJU</p>
        <img src="assets/logo1.png" style="width:170px;margin-bottom: 3px;" class="diplayTableMarginAutoMobile marginBottom5pxMobile" />
        <img src="assets/logo2.png" style="width:170px;margin-bottom: 3px"  class="diplayTableMarginAutoMobile marginBottom5pxMobile" />
        <img src="assets/logo3.png" style="width:170px" class="diplayTableMarginAutoMobile marginBottom5pxMobile" />
        <p style="font-size:14pt;font-weight: bold;margin:13px 0" class="textAlignCenterMobile">KORISNI LINKOVI</p>
        <a style="display: block;padding-bottom:8px" class="textAlignCenterMobile">Kako se koristi?</a>
        <a style="display: block;padding-bottom:8px" class="textAlignCenterMobile">Pravila i uslovi korišćenja</a>
        <a style="display: block;padding-bottom:8px" class="textAlignCenterMobile">Odricanje od odgovornosti</a>
        <a style="display: block;padding-bottom:8px" class="textAlignCenterMobile">Upravljanjem ličnim podacima</a>
        <a style="display: block;padding-bottom:8px" class="textAlignCenterMobile">Česta pitanja</a>
      </div>
    </div>
</div>
