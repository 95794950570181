import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  countries:any = [];
  data:any = {};
  configServise;
  constructor(configServise: ConfigService, private https: HttpClient, public dialog:MatDialog, private router: Router) {
    this.configServise = configServise;
   }

  ngOnInit() {
    if(this.configServise.token != null) this.router.navigate(['dashboard']);
    this.configServise.httpGet(this.configServise.getAPI() + 'countries').subscribe(data => {
      this.countries = data;
      this.data.country_db = 'cardian_rs';
    });
    /*this.configServise.httpPost(this.configServise.getAPI() + 'gorenje_cg/check/phone', { phone: "+381660738278", name: "Dusan" }, { headers: this.configServise.header }).subscribe(data => {
      console.log(data);
    });*/ 
  }

  validateForm(){
    if(this.data.username == "" || this.data.username == null || this.data.username == undefined) {
      this.openAlert("Unesite ispravan usrename!");
      return false
    }
    else if(this.data.password == "" || this.data.password == null || this.data.password == undefined) {
      this.openAlert("Unesite ispravnu sifru!");
      return false
    }
    return true;
  } 

  openAlert(msg): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.agree) {

      }
    });
  }

  login(){
    if(this.validateForm()){
      this.countries.forEach(element => {
        if(element.country_code == this.data.country_db) {
          this.data.country_name = element.name;
          this.data.currency = element.currency;
        }
      });
      this.configServise.login(this.data);
    }
  }

}
