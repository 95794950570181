<div style="margin:10px">
    <h2 style="text-align:center">
      Messages
    </h2>
  </div>

  <div class="mat-elevation-z8" style="width:60%;margin:auto">
    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.id }} </th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Messages </th>
        <td mat-cell *matCellDef="let row">{{row.message}}</td>
      </ng-container>

      <ng-container matColumnDef="answer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Answers </th>
        <td mat-cell *matCellDef="let row">{{row.answer}}</td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
        <td mat-cell *matCellDef="let row">{{row.user_name}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:200px;"></th>
        <td mat-cell *matCellDef="let row" style="width:200px; text-align: center;">
            <button mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="gotoEditMessage(row.id)">Pregled</button>
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="openDialog1(row.id)"> {{ configServise.translationJSON.delete }} </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 25, 50, 100, 250]">
    </mat-paginator>
</div>
