import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  dataSource: any = [];
  configServise;

  constructor(private https: HttpClient, configServise: ConfigService, public dialog:MatDialog,
              private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
                this.configServise =configServise;
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'action'];

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.configServise.httpGet(this.configServise.getAPI() + 'categories').subscribe(data => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = <Array<any>> data;
      this.table.dataSource = this.dataSource;
    });
  }

  deleteCategory(id){
    //if(confirm("Da li ste sigurni da hoćete da obrišete kategoriju?")){
      this.configServise.httpDelete(this.configServise.getAPI() + 'deletecategorie/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.dataSource.data = <Array<any>> data;
          this.changeDetectorRefs.detectChanges();
        }
        else {
          alert(tmp.text);
        }
      });
    //}
  }
  gotoEditCategory(id){
    this.router.navigate(['category'], { queryParams: { id: id }});
  }
  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: this.configServise.translationJSON.are_you_sure_you_want_to_delete_the_category + "?"
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if(result && result.agree){
        this.deleteCategory(id);
      }
    });
  }

}
