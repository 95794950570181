import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ConfigService } from 'src/app/services/config.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.css']
})
export class AddNewUserComponent implements OnInit {
  
  data: any = { };
  update:any = null;
  file: any;
  file2: any;
  validForm = true;
  configServise;
  clicked: any = false;
  messages: any = [];
  answer: any = '';

  constructor(private _location: Location, private https: HttpClient, configServise: ConfigService, private router: Router,public dialog:MatDialog , private route: ActivatedRoute, public datepipe: DatePipe) {
    this.configServise =configServise;
   }

   ngOnInit() {
    this.update = this.route.snapshot.queryParamMap.get("id");
    if(this.update){
      this.configServise.httpGet(this.configServise.getAPI() + 'users/' + this.update).subscribe(data => {
        this.data = data;
      });
      this.configServise.httpGet(this.configServise.getAPI() + 'users/' + this.update + '/messages').subscribe(data => {
        this.messages = data;
      });
    }
  }

  validateForm(){
    if(this.data.name == "" || this.data.name == null || this.data.name == undefined) {
      this.openAlert("Unesite ime i prezime korisnika!");
      return false
    }
    else if(this.data.email == "" || this.data.email == null || this.data.email == undefined) {
      this.openAlert("Unesite email!");
      return false
    }
    else if(this.data.phone == "" || this.data.phone == null || this.data.phone == undefined) {
      this.openAlert("Unesite broj telefona!"); 
      return false;
    }
    return true;
  } 

  openAlert(msg): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { question: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.agree) {
      }
    });
  }

  updateRecord(){
    if(this.validateForm()){
      let body = { id: this.update, name: this.data.name, email: this.data.email, phone: this.data.phone};
      this.configServise.httpPost(this.configServise.getAPI() + 'update_user', body).subscribe(data => {
        let tmp: any = data;
        if(tmp.result == true){
          this.router.navigate(['users']);
        }
      });
    }
  }

  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: "Sigurni ste da zelite blokirate korisnika" + "?"
       }
    });
     dialogRef.afterClosed().subscribe(result => {
      if(result && result.agree){
        this.block_Unblock_User(id);
      }
    });
  }

  openDialog2(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: "Sigurni ste da zelite odblokirate korisnika" + "?"
       }
    });
     dialogRef.afterClosed().subscribe(result => {
      if(result && result.agree){
        this.block_Unblock_User(id);
      }
    });
  }

  block_Unblock_User(id){
    this.clicked = true;
    this.configServise.httpPost(this.configServise.getAPI() + 'block_unblock_user/' + id, {}).subscribe(data => {
      this.data = data;
      this.clicked = false;
    });
  }

  backClicked() {
    //this.router.navigate(['users']);
    this._location.back();
  }
  
  answerMessage(){
    let body = { id: this.messages[this.messages.length - 1].id, answer: this.answer};
    this.configServise.httpPost(this.configServise.getAPI() + 'messages/answer_message', body).subscribe(data => {
      let tmp: any = data;
      if(tmp.result == true){
        this.answer = '';
        this.configServise.httpGet(this.configServise.getAPI() + 'users/' + this.update + '/messages').subscribe(data => {
          this.messages = data;
        });
      }
    });
  }
}

