<div style="margin:10px">
  <h2 style="text-align:center">
      <span *ngIf="update"> Pregled korisnika </span>
      <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="backClicked()"> Svi korisnici </button>
  </h2>
</div>
<mat-card>

  <mat-form-field style="width:30%;display:block">
      <input matInput [(ngModel)]="data.name" [placeholder]="configServise.translationJSON.user">
  </mat-form-field>

  <mat-form-field style="width:30%;display:block">
    <input matInput [(ngModel)]="data.email" [placeholder]="configServise.translationJSON.email">
  </mat-form-field>

  <mat-form-field style="width:30%;display:block">
    <input matInput [(ngModel)]="data.phone" [placeholder]="configServise.translationJSON.phone">
  </mat-form-field>

  <mat-form-field style="width:30%;display:block">
    <input matInput [(ngModel)]="data.card_number" placeholder="Broj kartice">
  </mat-form-field>

  <!--<div>
    <p style="font-weight:bold;margin-right:20px">Broj kartice: </p>
    <p>{{ data.card_number }}</p>
  </div>-->

  <div>
      <p style="font-weight:bold;margin-right:20px">Novcanik: </p>
      <p>{{ data.money_available }} RSD</p>
  </div>

  <div>
      <p style="font-weight:bold;margin-right:20px">Admin prava: </p>
      <p>{{data.admin ? configServise.translationJSON.yes : configServise.translationJSON.no}}</p>
  </div>

  <div>
      <p style="font-weight:bold;margin-right:20px">Aktivan: </p>
      <p>{{ data.active ? configServise.translationJSON.yes : configServise.translationJSON.no }}</p>
  </div>

  <div>
      <p style="font-weight:bold;margin-right:20px">Aktivacioni code: </p>
      <p>{{ data.activation_code }}</p>
  </div>

  <div>
    <button *ngIf="data.active == 1" mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white;" [disabled]="clicked" (click)="openDialog1(data.id);"><mat-icon>block</mat-icon> Block</button>
    <button *ngIf="data.active == 0" mat-raised-button [style.background-color]="configServise.color" style="color: white;" [disabled]="clicked" (click)="openDialog1(data.id);"><mat-icon>undo</mat-icon> Unblock</button>
  </div>

  <div>
    <p style="font-weight:bold;margin-right:20px">Verzija aplikacije: </p>
      <p>{{ data.version_app }}</p>
  </div>

  <div>
      <p style="font-weight:bold;margin-right:20px">Datum registacije </p>
      <p>{{ data.created_at | date:'yyyy-MM-dd' }}</p>
  </div>
  
  <div>
    <button *ngIf="update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="updateRecord()"> {{ configServise.translationJSON.save }} </button>
    <button *ngIf="update" mat-raised-button [style.background-color]="configServise.negativeColor"  routerLink="/users" style="margin-left:30px;color:white"> {{ configServise.translationJSON.cancel }} </button>
  </div>

  <div style="height: 50px;"></div>

  <mat-tab-group>
    <mat-tab label="Kupovine">      
      <app-gifts [users_id]="update"></app-gifts>
    </mat-tab>
    <mat-tab label="Poruke"> 
      <div style="height: 30px;"></div>
      <div *ngFor="let message of messages" style="width: 660px;">
        <p style="margin: 0;font-size:9pt;color: gray;">{{ message.created_at | date:'dd.MM.yyyy HH:mm' }}</p>
        <p style="width: 400px;padding:5px;background-color: lightgray;border-radius: 7px;margin-top:0">{{message.message}}</p>

        <div *ngIf="message.answered_at" style="padding-left: 250px;">
          <p style="margin: 0;font-size:9pt;color: gray;text-align: right;">{{ message.answered_at | date:'dd.MM.yyyy HH:mm' }}</p>
          <p style="width: 400px;padding:5px;background-color: lightgray;border-radius: 7px;margin-top:0">{{message.answer}}</p>
        </div>
      </div>
      
      <div *ngIf="messages && messages.length > 0 && !messages[messages.length - 1].answered_at">
        <mat-form-field style="width: 500px;display:block">
            <textarea matInput [(ngModel)]="answer" placeholder="Ogdovor" style="min-height: 80px;" cdkTextareaAutosize #autosize="cdkTextareaAutosize"></textarea>
        </mat-form-field>
        <div>
            <button *ngIf="update" mat-raised-button [style.background-color]="configServise.color" style="color: white" (click)="answerMessage()"> Odgovori </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div style="height: 50px;"></div>
</mat-card>
