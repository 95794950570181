<div style="margin:10px">
    <h2 style="text-align:center">
      Dashboard
    </h2>
</div>
<div style="height: 30px;"></div>
<mat-card *ngFor="let item of data" [class]="item.navigate != '' ? 'hover' : '' " style="width:250px;margin:25px 50px;display: inline-block;vertical-align: top;cursor:pointer;outline: none;" (click)="navigate(item)">
    <div style="display:inline-block;width: 60%;">                 
        <div>
            <mat-icon style="font-size:40px;margin-left:calc(50% - 20px);" [style.color]="configService.balckColor">{{ item.icon }}</mat-icon>
        </div>
        <p style="text-align: center;font-size:18px">{{ item.text }}</p>
    </div>
    <div style="display:inline-block;width: 40%;vertical-align: top;">        
        <h2 style="text-align: center;margin-top:30px">{{ item.value }}</h2>
    </div>
    <div style="display:inline-block;width: 60%;">
        <p *ngIf="item.extra_text" style="text-align: center;font-size:14px;margin-top:0">{{item.extra_text}}</p>
    </div> 
    <div style="display:inline-block;width: 40%;vertical-align: top;">
        <p *ngIf="item.extra_value || item.extra_value == 0" style="text-align: center;font-size:14px;margin-top:0">{{ item.extra_value }}</p>
    </div>
</mat-card>