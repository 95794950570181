
<div style="margin:10px">
  <h2 style="text-align:center">
    {{ configServise.translationJSON.categories }}
    <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" routerLink="/category"> {{ configServise.translationJSON.add_new }} </button>
  </h2>
</div>
<div class="mat-elevation-z8" style="width:50%;margin:auto">
    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.id }} </th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ configServise.translationJSON.name }} </th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:200px;"></th>
        <td mat-cell *matCellDef="let row" style="width:200px">
            <button mat-raised-button [style.background-color]="configServise.color" style="color:white;text-align: center;margin-right: 5px;" (click)="gotoEditCategory(row.id)"> <mat-icon>edit</mat-icon> </button>
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="openDialog1(row.id);$event.stopPropagation()"> <mat-icon>delete_forever</mat-icon> </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover" (click)="gotoEditCategory(row.id)" style="cursor:pointer"></tr>
    </table>

    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 25, 50, 100, 250]">
    </mat-paginator>
</div>