<h2 style="text-align:center">
    {{ configServise.translationJSON.logs }}
</h2>
<div style="height:15px"></div>
<mat-tab-group (selectedTabChange)="tabchanged($event)">
    <mat-tab label="PUSH">
        <app-push-logs></app-push-logs>
    </mat-tab>
    <mat-tab label="AUTOPUSH">
        <app-auto-push-logs></app-auto-push-logs>
    </mat-tab>
    <mat-tab label="SMS">
        <app-sms-logs></app-sms-logs>
    </mat-tab>
</mat-tab-group>