import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { ConfigService } from '../services/config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {saveAs as importedSaveAs} from "file-saver";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

@Component({
  selector: 'app-boughtartical',
  templateUrl: './bought-artical.component.html',
  styleUrls: ['./bought-artical.component.css']
})
export class BoughtArticalComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  dataSource: any = [];
  configServise;
  allData:any = [];
  currFilterValue = "";
  searchColumn = 'all';
  alert = false;
  alertText = "* Polja su obavezna";
  popup = false;
  dataIzvestaj: any = { tip: 0 };
  waiting = false;
  filename;
  pageIndex = "0";
  pageSize = "15";
  length = 0;
  doneLoading = true;
  wait = 0;

  constructor(private https: HttpClient, configServise: ConfigService,
              public datepipe: DatePipe, public route: ActivatedRoute,
              private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
                this.configServise = configServise;
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'user_name', 'phone', 'company_name', 'shop_name', 'artical_name', 'quantity', 'created_at', 'points', 'sent_date', 'user_active', 'zuta_tacka', 'action'];

  ngOnInit() {
    let pageIndex = this.route.snapshot.queryParamMap.get("pageIndex");
    if(pageIndex) this.pageIndex = pageIndex;
    let pageSize = this.route.snapshot.queryParamMap.get("pageSize");
    if(pageSize) this.pageSize = pageSize;
    let currFilterValue = this.route.snapshot.queryParamMap.get("currFilterValue");
    if(currFilterValue) this.currFilterValue = currFilterValue; 
    this.dataSource = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.doneLoading = false;
    this.configServise.httpGet(this.configServise.getAPI() + 'bougtharticals/' + this.pageIndex + '/' + this.pageSize + (this.currFilterValue ? '/' +  this.currFilterValue : '')).subscribe(data => {
      this.length = (<any>data).count;
      this.doneLoading = true;
      this.allData = <Array<any>> (<any>data).data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.allData;
      this.table.dataSource = this.dataSource;
    });
  }

  deleteBoughtArtical(id){
    if(confirm(this.configServise.translationJSON.are_you_sure_you_want_to_delete_the_order + "?")){
      this.configServise.httpDelete(this.configServise.getAPI() + 'deleteboughtartical/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.applyFilter(this.currFilterValue);
        }
        else {
          alert(this.configServise.translationJSON.failed_delete);
        }
      });
    }
  }
  gotoDetail(id){
    this.router.navigate(['bougthartical'], { queryParams: { id: id }});
  }

  onPaginateChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageSize: e.pageSize,
        pageIndex: e.pageIndex,
        currFilterValue: this.currFilterValue
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    //this.ngOnInit();
    this.ngAfterViewInit();
  }
  
  applyFilter(filterValue: string = null) {
    if(filterValue == "") {
      this.currFilterValue = null;
    }
    else {
      if(filterValue) this.currFilterValue = filterValue;
      else filterValue = this.currFilterValue;
    }
    this.wait++
    let self = this;
    setTimeout(() => { 
      self.wait--
      if(self.wait == 0) {   
        self.pageIndex = "0";  
        self.router.navigate([], {
          relativeTo: self.route,
          queryParams: {
            pageSize: self.pageSize,
            pageIndex: self.pageIndex,
            currFilterValue: self.currFilterValue
          },
          queryParamsHandling: 'merge',
          // preserve the existing query params in the route
          skipLocationChange: false
          // do not trigger navigation
        });   
        self.ngAfterViewInit();
      }
    }, 1000);
  }
  
  generisiExcel(){
    this.filename = 'boughtarticals_generated_' + Date.now() + '_by_uid' + this.configServise.me.id;
    if(this.dataIzvestaj.tip || !this.dataIzvestaj.tip && this.dataIzvestaj.od && this.dataIzvestaj.do){
      if(this.dataIzvestaj.do <= this.dataIzvestaj.od){
        this.alert = true;
        this.alertText = this.configServise.translationJSON.incorrect_entry;
      } else {
        this.alert = false;
        this.dataIzvestaj.od = this.datepipe.transform(this.dataIzvestaj.od, 'yyyy-MM-dd');
        this.dataIzvestaj.do = this.datepipe.transform(this.dataIzvestaj.do, 'yyyy-MM-dd');
        this.waiting = true;
        this.configServise.httpPost(this.configServise.getAPI() + 'generateboughtexcel/' + this.filename, this.dataIzvestaj).subscribe(data => {
          let tmp: any;
          tmp = (<any>data).result;
          this.waiting = false;
          let workbook = new ExcelJS.Workbook();
          let worksheet = workbook.addWorksheet("Worksheet");
          worksheet.getRow(1).height = 30;
          worksheet.mergeCells('A1:G1');
          worksheet.getCell('A1').value = "Trenutno stanje" + (this.dataIzvestaj.tip ? " do sada" : " od " + this.dataIzvestaj.od + " do " + this.dataIzvestaj.do);
          worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getCell('A1').font = { bold: true, size: 14 };

          let header = [
            this.configServise.translationJSON.USER_NAME,
            this.configServise.translationJSON.MOBILE,
            this.configServise.translationJSON.CITY,
            this.configServise.translationJSON.ADDRESS,
            this.configServise.translationJSON.JMBG,
            this.configServise.translationJSON.BUYER,
            this.configServise.translationJSON.BUSINESS_UNIT,
            this.configServise.translationJSON.NAME_OF_GIFT,
            this.configServise.translationJSON.QUANTITY,
            this.configServise.translationJSON.DATE,
            this.configServise.translationJSON.POINTS,
            this.configServise.translationJSON.PRICE,
            this.configServise.translationJSON.ACTIVE_BLOCKED,
            this.configServise.translationJSON.POSTING_DATE,
            this.configServise.translationJSON.SERIAL_NUMBER,
            this.configServise.translationJSON.YELLOW_DOT
          ];
          worksheet.addRow(header);
          worksheet.getRow(2).height = 25;

          worksheet.getColumn(1).width = 20;
          worksheet.getColumn(2).width = 20;
          worksheet.getColumn(3).width = 20;
          worksheet.getColumn(4).width = 25;
          worksheet.getColumn(5).width = 25;
          worksheet.getColumn(6).width = 25;
          worksheet.getColumn(7).width = 25;
          worksheet.getColumn(8).width = 25;
          worksheet.getColumn(9).width = 25;
          worksheet.getColumn(10).width = 25;
          worksheet.getColumn(11).width = 25;
          worksheet.getColumn(12).width = 25;
          worksheet.getColumn(13).width = 25;
          worksheet.getColumn(14).width = 25;
          
          let headerRow = worksheet.getRow(2);
          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '4C5C66' },
              bgColor: { argb: '4C5C66' }
            }
            cell.font = {
              bold: true,
              color: { argb: 'ffffff' },
              size: 10
            }
            cell.alignment = { wrapText: true };
          });

          tmp.forEach(row => {
            let p_arr = [
              row.user_name,
              row.phone,
              row.city,
              row.address,
              row.jmbg,
              row.company_name,
              row.shop_name,
              row.artical_name,
              row.quantity,
              row.created_at,
              row.points,
              row.price,
              (row.user_active ? this.configServise.translationJSON.ACTIVE : this.configServise.translationJSON.BLOCKED),
              row.sent_date,
              row.serial_number,
              row.zuta_tacka,
            ];	
            worksheet.addRow(p_arr);
            p_arr = [];
          });

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'poruceni_pokloni.xlsx');
          });
              
          this.closePopup();
        }, err => {
          alert(this.configServise.translationJSON.an_error_has_occurred + "!");
        });
      }
    }
    else {
      this.alertText = this.configServise.translationJSON.fields_are_required;
      this.alert = true;
    }
  }
  
  donwload(f){  
    let self = this;  
    setTimeout(function(){
      self.configServise.httpGet('http://api.digitalspark-gorenje.com' + '/mediaexcel/' + f, { responseType: 'blob' }).subscribe(blob => {
        self.closePopup();
        self.waiting = false;
        importedSaveAs(blob, f);
      }, err => {
        self.donwload(f);
      });
    }, 10000);
  }

  openPopup(){
    this.waiting = false;
    this.alert = false;
    this.dataIzvestaj = { tip: 0 };
    this.configServise.sidenavToggle();
    this.popup = true;
  }
  closePopup(){
    this.waiting = false;
    this.alert = false;
    this.dataIzvestaj = { tip: 0 };
    this.configServise.sidenavToggle();
    this.popup = false;
  }
}
