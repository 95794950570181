import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfigService } from '../services/config.service';
import {saveAs as importedSaveAs} from "file-saver";

@Component({
  selector: 'app-gifts',
  templateUrl: './gifts.component.html',
  styleUrls: ['./gifts.component.css']
})
export class GiftsComponent implements AfterViewInit,OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  allData:any = [];
  dataSource: any = [];
  searchColumn = "all";
  configServise;
  currFilterValue = "";
  pageIndex = "0";
  pageSize = "15";
  doneLoading = true;
  length = 0;
  wait = 0;
  vouchers: any = [];
  vouchers_amounts: any = [];
  vouchers_id: any = 'all';
  vouchers_amounts_value: any = 'all';
  alert = false;
  alertText = "* Polja su obavezna";
  popup = false;
  dataIzvestaj: any = { tip: 0 };
  waiting = false;

  @Input() users_id = null;


  constructor(private https: HttpClient, configServise: ConfigService, public dialog:MatDialog, public route: ActivatedRoute,public datepipe: DatePipe,
    private changeDetectorRefs: ChangeDetectorRef, private router: Router) {
    this.configServise =configServise  
  }

  displayedColumns = ['id', 'user_id', 'phone', 'cash_back', 'vouchers_id', 'vouchers_amounts_id', 'type', 'date', 'landing_link', 'action'];


  ngAfterViewInit(){
    this.doneLoading = false;
    this.dataSource = new MatTableDataSource();
    let body: any = {};
    if(this.currFilterValue) body.currFilterValue = this.currFilterValue;
    if(this.vouchers_id != 'all') body.vouchers_id = this.vouchers_id;
    if(this.vouchers_amounts_value != 'all') body.vouchers_amounts_value = this.vouchers_amounts_value;
    if(this.users_id) body.users_id = this.users_id;
    this.configServise.httpPost(this.configServise.getAPI() + 'gifts/' + this.pageIndex + '/' + this.pageSize, body).subscribe(data => {
      this.length = (<any>data).count;
      this.doneLoading = true;
      this.allData = <Array<any>> (<any>data).data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.allData;
      this.table.dataSource = this.dataSource;
    });
    this.configServise.httpGet(this.configServise.getAPI() + 'vouchers').subscribe(data => {
      this.vouchers = data;
    });
  }
  
  getVouchersAmountsByVoucherID(voucher_id){
    this.vouchers_amounts = [];
    this.vouchers_amounts_value = 'all';
    this.configServise.httpGet(this.configServise.getAPI() + 'vouchers/' + voucher_id + '/all_vouchers_amounts').subscribe(data => {
      this.vouchers_amounts = data;
    });
  }

  ngOnInit(){
    let pageIndex = this.route.snapshot.queryParamMap.get("pageIndex");
    if(pageIndex) this.pageIndex = pageIndex;
    let pageSize = this.route.snapshot.queryParamMap.get("pageSize");
    if(pageSize) this.pageSize = pageSize;
    let currFilterValue = this.route.snapshot.queryParamMap.get("currFilterValue");
    if(currFilterValue) this.currFilterValue = currFilterValue;  
    let vouchers_id = this.route.snapshot.queryParamMap.get("vouchers_id");
    if(vouchers_id) {
      this.vouchers_id = parseInt(vouchers_id);  
      this.getVouchersAmountsByVoucherID(this.vouchers_id);
      let vouchers_amounts_value = this.route.snapshot.queryParamMap.get("vouchers_amounts_value");
      if(vouchers_amounts_value) this.vouchers_amounts_value = parseInt(vouchers_amounts_value);  
    } 
    this.dataSource = new MatTableDataSource();
  }

  deleteGift(id){
      this.configServise.httpDelete(this.configServise.getAPI() + 'delete_gift/' + id).subscribe(data => {
        let tmp:any = data;
        if(tmp.result != false){
          this.dataSource.data = <Array<any>> data;
          this.changeDetectorRefs.detectChanges();
        }
        else {
          alert(tmp.text); 
        }
      });
  }

  onPaginateChange(e){
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageSize: e.pageSize,
        pageIndex: e.pageIndex,
        currFilterValue: this.currFilterValue,
        vouchers_id: this.vouchers_id,
        vouchers_amounts_value: this.vouchers_amounts_value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    //this.ngOnInit();
    this.ngAfterViewInit();
  }
  
  gotoReviewGift(id){
    this.router.navigate(['gift'], { queryParams: { id: id }});
  }

  openDialog1(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { question: "Sigurni ste da zelite da obriste poklon?"
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.agree){
        this.deleteGift(id);
      }
    });
  }

  applyFilter(filterValue: string = null) {
    //mozemo g i obrisati jer ne zelimo vise da to radimo na frontu, to nam sada mora raditi back, kontas ? aaaaaaaaaaa ok
    this.wait++ // dakle cim sam naisao na ovo polje uvecao sam brojac, jer potencijalno ovo moze biti poslednje slovo od kojeg treba sacekati jednu sekundu, ok ?  ok
    let self = this;
    setTimeout(() => { 
      console.log(self.currFilterValue);
      self.wait-- // nakon sto sam usao u setTimeout dakle prosla je jedna sekunda, ja umanjujem ono slovo nakon kojeg sam usao, ok ?ok
      if(self.wait == 0) {   // PITAM JEL MI BROJAC 0, dakle ako je u medjuvremenu uletelo jos bar jedno slovo, onda je ono poveclo brojac, i onda ovo slovo zbog kojeg sam usao u ovaj Timeout zapravo nije poslednje, ok ?ok
        self.pageIndex = "0";   // e to ta cela logika koju sam osmislio za detekciju, a u nastavku se samo pripremam za request, odnosno ovim delom
        self.router.navigate([], {
          relativeTo: self.route,
          queryParams: {
            pageSize: self.pageSize,
            pageIndex: self.pageIndex,
            currFilterValue: self.currFilterValue,
            vouchers_id: this.vouchers_id,
            vouchers_amounts_value: this.vouchers_amounts_value
          },
          queryParamsHandling: 'merge',
          // preserve the existing query params in the route
          skipLocationChange: false
          // do not trigger navigation
        });    // ovim delom sam samo izmenio link u browseru
        self.ngAfterViewInit(); // ovim pozviom sam naterao da se ponovo povuku podaci za backenda, s tim sto ovde osecam da bi trebalo da se pozovu obe metode, i onInit, i after, jer poneta je da se ponovo povuku informacije iz url-a
      }
    }, 1000);
  }

  ResetFilter (){
    this.currFilterValue = "";
    this.vouchers_id = 'all';
    this.vouchers_amounts_value = 'all';    
    
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        vouchers_id: this.vouchers_id,
        vouchers_amounts_value: this.vouchers_amounts_value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });

    this.ngAfterViewInit();
  }

  generisiExcel(){
    if(this.dataIzvestaj.tip || !this.dataIzvestaj.tip && this.dataIzvestaj.od && this.dataIzvestaj.do){
      if(this.dataIzvestaj.do <= this.dataIzvestaj.od){
        this.alert = true;
        this.alertText = this.configServise.translationJSON.incorrect_entry;
      } else {
        this.alert = false;
        this.dataIzvestaj.od = this.datepipe.transform(this.dataIzvestaj.od, 'yyyy-MM-dd');
        this.dataIzvestaj.do = this.datepipe.transform(this.dataIzvestaj.do, 'yyyy-MM-dd');
        this.waiting = true;
        this.dataIzvestaj.filename = 'gifts_generated_' + Date.now() + '_by_uid' + this.configServise.me.id + '.xlsx';
        // 'generateuserexcel'
        this.configServise.httpPost(this.configServise.getAPI() + 'generategiftsexcel', this.dataIzvestaj).subscribe(data => {
          let tmp: any;
          tmp = (<any>data).result;
            let self = this;
            self.closePopup();
            self.waiting = false;
            let filename = tmp.split("/")[tmp.split("/").length - 1];
            self.https.get(tmp, { responseType: 'blob' }).subscribe(blob => {
              importedSaveAs(blob, filename);
            });
        }, err => {
              let self = this;
              self.dataIzvestaj;
              let f = self.dataIzvestaj.filename;
              self.donwload(f);
        });
      }
    }
    else {
      this.alertText = this.configServise.translationJSON.fields_are_required;
      this.alert = true;
    }
  }

  donwload(f){  
    let self = this;  
    /*setTimeout(function(){
      self.configServise.httpGet('http://api.digitalspark-gorenje.com' + '/mediaexcel/' + f, { responseType: 'blob' }).subscribe(blob => {
        self.closePopup();
        self.waiting = false;
        importedSaveAs(blob, f);
      }, err => {
        self.donwload(f);
      });
    }, 10000);*/
  }

  openPopup(){
    this.waiting = false;
    this.alert = false;
    this.dataIzvestaj = { tip: 0 };
    this.configServise.sidenavToggle();
    this.popup = true;
  }
  closePopup(){
    this.waiting = false;
    this.alert = false;
    this.dataIzvestaj = { tip: 0 };
    this.configServise.sidenavToggle();
    this.popup = false;
  }

}


