
<div style="margin:10px">
  <h2 style="text-align:center">
    Vendori
    <button mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" routerLink="/voucher">Dodaj vendora</button>
  </h2>
</div>
<div class="mat-elevation-z8" style="width:80%;margin:auto">  
    <div style="display:inline-block">
      <mat-form-field style="margin:20px;margin-bottom: 0;width:350px">
        <input matInput [(ngModel)]="currFilterValue" (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
    </div>
    
    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ configServise.translationJSON.name }}</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>   

      <ng-container matColumnDef="promotion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Promocija</th>
        <td mat-cell *matCellDef="let row">{{row.promotion ? "Da" : "Ne"}}</td>
      </ng-container>

      <ng-container matColumnDef="promotion_percent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Procenat promocije</th>
        <td mat-cell *matCellDef="let row" style="text-align: center;">{{row.promotion_percent ?  row.promotion_percent + '%' : '' }}</td>
      </ng-container>

      <ng-container matColumnDef="cash_back_percent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Procenat povratka novca</th>
        <td mat-cell *matCellDef="let row" style="text-align: center;">{{row.cash_back_percent ? row.cash_back_percent + '%' : '' }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:200px;"></th>
        <td mat-cell *matCellDef="let row" style="width:200px">
            <button mat-raised-button [style.background-color]="configServise.color" style="margin-right:10px;color:white" (click)="gotoEditVoucher(row.id)">{{ configServise.translationJSON.edit }}</button>
            <button mat-raised-button [style.background-color]="configServise.negativeColor" style="color: white" (click)="openDialog1(row.id)">{{ configServise.translationJSON.delete }}</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 25, 50, 100, 250]">
    </mat-paginator>
</div>