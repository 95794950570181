
<div style="margin:10px">
  <h2 style="text-align:center">
    {{ configServise.translationJSON.administration }}
    <button *ngIf="user.id != 0" mat-raised-button [style.background-color]="configServise.color" style="float: right;color:white" (click)="formaZaDodavanje()"><mat-icon>person</mat-icon>{{ configServise.translationJSON.add_administrator }}</button>
  </h2>
</div>
<div style="height:40px"></div>
<p><b>Napomena: </b> Potrebno je odabrati postojećeg korisnika i dodeliti mu admin prava, ili dodati novog.</p>
<div>
  <div *ngIf="user.id != 0">
    <div style="display: inline-block;width:35%">
      <mat-form-field *ngIf="user.id == null" style="width:100%">
          <input type="text" [placeholder]="configServise.translationJSON.name_of_user" matInput [matAutocomplete]="auto" [(ngModel)]="user.name" (keyup)="filter($event.target.value)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUser($event)">
              <mat-option  *ngFor="let option of filteredOptions" [value]="option.id">
                  {{option.name}} 
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="user.id != null">
          <span style="font-size:9pt;display:block;margin-bottom:10px">{{ configServise.translationJSON.name_of_user }}</span>
          <div>
              <p style="margin-top:5px;margin-right:40px;display:inline-block;vertical-align: top;">{{ user.name }}</p>
              <button mat-mini-fab [style.background-color]="configServise.negativeColor" style="color: white;width:30px;height:30px;" (click)="clearUserId()"><mat-icon style="margin-top:-9px">clear</mat-icon></button>
          </div>
      </div>
    </div>
    <div style="display: inline-block;vertical-align:top;">
      <p *ngIf="wait > 0" style="margin: 20px;">{{ configServise.translationJSON.loading }}</p>
    </div>
  </div>
  <div *ngIf="user.id != null" style="padding: 40px;">
    <div *ngIf="user.id == 0">
      <mat-form-field style="width:35%;display: block;">
        <input type="text" [placeholder]="configServise.translationJSON.name_surname" matInput [(ngModel)]="user.name">
      </mat-form-field>
      <mat-form-field style="width:35%;display: block;">
        <input type="text" [placeholder]="configServise.translationJSON.phone" matInput [(ngModel)]="user.phone">
      </mat-form-field>
      <mat-form-field style="width:35%;display: block;">
        <input type="text" [placeholder]="configServise.translationJSON.email" matInput [(ngModel)]="user.email">
      </mat-form-field>
      <mat-form-field style="width:35%;display: block;">
        <input type="password" [placeholder]="configServise.translationJSON.password" matInput [(ngModel)]="user.password">
      </mat-form-field>
    </div>

    <mat-checkbox [(ngModel)]="user.admin.all" (click)="click_all()" style="display: block;">{{ configServise.translationJSON.all }}</mat-checkbox>
    <div style="padding-left: 20px;">
      <mat-checkbox [(ngModel)]="user.admin.dashboard" style="display: block;">{{ configServise.translationJSON.home_page }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin.administration" style="display: block;">{{ configServise.translationJSON.administration }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin.users" (click)="click_users()" style="display: block;">{{ configServise.translationJSON.users }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin.vouchers" (click)="click_vouchers()" style="display: block;">{{ configServise.translationJSON.vouchers }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin.categories" (click)="click_categories()" style="display: block;">{{ configServise.translationJSON.categories }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin.terms_and_conditions" (click)="click_terms_conditions()" style="display: block;">{{ configServise.translationJSON.terms_and_conditions }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin['qr-barcode-codes']" (click)="click_qr_barcode()" style="display: block;">QR Barcode codes</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin['messages']" (click)="click_message()" style="display: block;">Messages</mat-checkbox>
      <mat-checkbox [(ngModel)]="user.admin['gifts']" (click)="click_gifts()" style="display: block;">Gifts</mat-checkbox>
    </div>
  </div>
  <div style="height: 40px;"></div>
  <button *ngIf="user.id != null" mat-raised-button [style.background-color]="configServise.color" style="color:white" (click)="save()"><mat-icon>save</mat-icon>{{ configServise.translationJSON.save }}</button>
  <button *ngIf="user.id == 0" mat-raised-button [style.background-color]="configServise.negativeColor" style="color:white;margin-left: 30px;" (click)="user.id = null"><mat-icon>clear</mat-icon>{{ configServise.translationJSON.cancel }}</button>
</div>